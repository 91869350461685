import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Home() {
 
  return (
    <div className="header ">
      <Header />
      <main>
      <div className="terms_contition">
        <h1>Navigate, Trade, Succeed - The Adverb Way.</h1>
        <div className="container">
          <div className="row justify-center">
            <div className="col-lg-10">
              <div class="mx-auto u-para--readable">
                <h4>Welcome to Adverb Exchange,</h4>
                <p class="u-para--readable mb-6">
                "Where cryptocurrency trading meets innovation, Adverb Exchange empowers you to confidently trade, invest, and grow your cryptocurrency portfolio. With our intuitive interface and comprehensive features, experience the simplicity and security of Adverb Exchange as you unlock the full potential of the crypto market
                  {/*  */}
                </p>
                <h4>OUR MISSION</h4>
                <p class="u-para--readable mb-6">
                At Adverb Exchange,  our mission is to simplify the complexities of the digital asset world and provide you with a seamless and secure trading experience.</p>
                <h4>ADVERB FEATURES</h4>
                <p class="u-para--readable mb-6"> "P2P Trading: Experience the True Power of Decentralized Trading on Adverb Exchange's Peer-to-Peer Platform. Enjoy enhanced privacy and flexibility as you engage in secure transactions without intermediaries. Our platform provides a secure escrow system, protecting both buyers and sellers for a smooth and trustworthy trading experience." </p>
                <p class="u-para--readable mb-6"> Staking: At Adverb Exchange, we understand the importance of maximizing the potential of your digital assets. That's why we offer a staking feature that allows you to earn passive income by participating in the proof-of-stake consensus mechanism. By staking your assets with us, you can contribute to the security and decentralization of the blockchain while earning rewards. </p>
                <p class="u-para--readable mb-6">  Spot Trading: Adverb Exchange provides a robust spot trading platform for users to buy and sell digital assets with ease. With a user-friendly interface and advanced trading tools, you can execute trades quickly and efficiently. Our platform supports a wide range of cryptocurrencies, allowing you to diversify your portfolio and take advantage of market opportunities. </p>
                <p class="u-para--readable mb-6">  Launchpad: Our launchpad feature is designed to support the growth of innovative projects in the blockchain space. Through our platform, users have the opportunity to participate in token sales and Initial DEX Offerings (IDO) of promising projects. By leveraging our launchpad, you can be at the forefront of the latest blockchain innovations and potentially benefit from early investments </p>
                
               
              </div>
            </div>
          </div>
        </div>
      </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
