import React, {useState, useEffect} from "react";
import Header from "./Header";
import {Button} from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import {Link, useNavigate} from "react-router-dom";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {toast} from "react-toastify";

function Home() {
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const initialFormValue = {
    otp: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [buttonLoader1, setbuttonLoader1] = useState(false);

  const navigate = useNavigate();
  const {otp} = formValue;

  const formSubmit = async (payload) => {
    // return
    if (formValue.otp != "") {
      // return false

      var obj = {
        emailOtp: otp,
        email: localStorage.getItem("useremail"),
      };

      var data = {
        apiUrl: apiService.emailotpverify,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
        navigate("/login");
      } else {
        toast.success(resp.Message);
      }
    } else {
      toast.error("Enter OTP");
    }
  };

  const resendOTP = async (payload) => {
    var obj = {
      email: localStorage.getItem("useremail"),
    };

    var data = {
      apiUrl: apiService.resendCode,
      payload: obj,
    };
    setbuttonLoader1(true);
    var resp = await postMethod(data);
    setbuttonLoader1(false);
    if (resp.status) {
      toast.success(resp.Message);
    } else {
      toast.error(resp.Message);
    }
  };

  const handleChange = async (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    let formData = {...formValue, ...{[name]: value}};
    setFormValue(formData);
  };
  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form">
          <div className="container">
            <div className="row ">
              <div className="col-lg-1"></div>
              <div className="col-lg-6">
                <div className="card_login">
                  <h3>Enter OTP To Activate the account</h3>
                  <div>
                    <div class="form_login_input">
                      <label>Email OTP</label>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Enter OTP"
                        name="otp"
                        value={otp}
                        onChange={handleChange}
                      />
                    </div>

                    <div class="form_login_input">
                    {buttonLoader == false ? (
                        <Button
                          onClick={formSubmit}
                        >
                          Continue
                        </Button>
                      ) : (
                        <Button>
                          Loading...
                        </Button>
                      )}
                    </div>

                    <div className="col-lg-12">
                      <p className="bottom_linnk">
                        Did not receive the email? Check your spam filter, or
                      </p>
                      
                         
                    </div>

                    <div class="form_login_input">
                         
                         {buttonLoader1 == false ? (
                            <Button onClick={resendOTP}>Resend Code</Button>
                          ) : (
                            <Button onClick={resendOTP}>Loading...</Button>
                          )}

                      </div>

                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
