import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import validator from "validator";
import useStateRef from "react-usestateref";

function Home() {
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const initialFormValue = {
    email: "",
    password: "",
    confirmPassword: "",
    RefferedBy: ""
  };

  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [nameValidate, setnameValidate, nameValidateref] = useStateRef(false);
  const [emailValidate, setemailValidate, emailValidateref] = useStateRef(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] = useStateRef(false);
  const [confirmPasswordValidate, setconfirmPasswordValidate, confirmPasswordValidateref] = useStateRef(false);
  const [isChecked, setIschecked] = useState(false);
  const [Terms, setTerms] = useStateRef(false);
  const [termsValidation, setTermsValidation] = useState(false);
  const [refValidation, setRefValidation] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  //const [RefferedBy, setRefferedBy] = useState("");

  const navigate = useNavigate();

  const {
    name,
    email,
    password,
    confirmPassword,
    RefferedBy
  } = formValue;

  useEffect(() => {
    window.scrollTo(0, 0)
    var currURL = window.location.href;
    var refferalId = currURL.split("invite/")[1];
    //setRefferedBy(refferalId);
    formValue.RefferedBy = refferalId;
  }, []);

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const handleTerms = (event) => {
    if (event.target.checked) {
      setTerms(true);
    } else {
      setTerms(false);
    }
    setIschecked((current) => !current);
  };

  const validate = async (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Name is a required field";
      setnameValidate(true);
    } else if(values.name.length <3){
      errors.name = "Enter 3 later";
      setnameValidate(true);
    }else{
      setnameValidate(false);
    }

    if (!values.email) {
      errors.email = "Email is a required field";
      setemailValidate(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
      setemailValidate(true);
    }else{
      setemailValidate(false);
    }

    if (!values.password) {
      errors.password = "Password is a required field";
      setpasswordValidate(true);
    } else if (
      validator.isStrongPassword(formValue.password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setpasswordValidate(false);
    } else {
      errors.password = "Enter strong password";
      setpasswordValidate(true);
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm password is a required field";
      setconfirmPasswordValidate(true);
    }else{
      setconfirmPasswordValidate(false);
    }

    if (
      values.password != values.confirmPassword
    ) {
      setconfirmPasswordValidate(true);
      errors.confirmPassword = "Password and confirm password does not match";
    }else{
      setconfirmPasswordValidate(false);
    }

    if (!Terms) {
      errors.terms = "Terms is a required field";
      setTermsValidation(true);
    }
    else {
      errors.terms = "";
      setTermsValidation(false);
    }

    // if (!values.RefferedBy) {
    //   errors.RefferedBy = "Referral code is a required field";
    //   setRefValidation(true);
    // }


    setvalidationnErr(errors);
    return errors;
  };



  const formSubmit = async () => {
    validate(formValue);
    if (
      nameValidateref.current == false &&
      emailValidateref.current == false &&
      passwordValidateref.current == false &&
      confirmPasswordValidateref.current == false &&
      Terms == true
    ) {
      console.log(formValue, "=-=-=-=-")
      var data = {
        apiUrl: apiService.signup,
        payload: formValue,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      localStorage.setItem("useremail", resp.email);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
        navigate("/otp");
      } else {
        toast.error(resp.Message);
      }
    } else {
      console.log(nameValidateref.current,emailValidateref.current,passwordValidateref.current,confirmPasswordValidateref.current, "=-=-1=-=-")
    }
  };


  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form">
          <div className="container">
            <div className="row ">

              <div className="col-lg-6">
                <div className="card_login">
                  <h3>Create Personal Account</h3>
                  <div>
                    <div class="form_login_input">
                      <label>Name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Name"
                        name="name"
                        value={name}
                        onChange={handleChange}
                      />

                      <div>
                        {nameValidateref.current == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.name}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="form_login_input">
                      <label>Personal Email</label>

                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                      />

                      <div>
                        {emailValidateref.current == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.email}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="form_login_input">
                      <label>Password</label>

                      <input
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={handleChange}
                      />

                      <div>
                        {passwordValidateref.current == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.password}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="form_login_input">
                      <label>ConfirmPassword</label>
                      <input
                        type="password"
                        class="form-control"
                        placeholder="ConfirmPassword"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleChange}
                      />

                      <div>
                        {confirmPasswordValidateref.current == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.confirmPassword}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div class="form_login_input">
                      <label>Referred BY</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Referral ID"
                        name="RefferedBy"
                        value={RefferedBy}
                        maxLength={25}
                        onChange={handleChange}
                      />

                      {/* <div>
                        {refValidation == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.RefferedBy}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div> */}

                    </div>
                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                        onChange={handleTerms}
                        value={isChecked}
                      />
                      <label class="form-check-label" for="exampleCheck1">
                        I have read and agree to Adverb's{" "}
                        <a href="">Terms of Service</a> and{" "}
                        <a href="">Privacy Policy</a>.
                      </label>

                      <div>
                        {termsValidation == true ? (
                          <p style={{ color: "red" }}>
                            {" "}
                            Terms and Conditions is required
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="form_login_input">
                      {buttonLoader == false ? (
                        <Button
                          onClick={formSubmit}
                        >
                          Sign up
                        </Button>
                      ) : (
                        <Button>
                          Loading...
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex align-items-center">
                <img
                  src={require("../Image/bitcoin-p2p/pana.png")}
                  className="img-fluid clo_login_in"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
