import React, {useState, useEffect} from "react";
import Header from "./Header";
import {Button} from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import {Link} from "react-router-dom";

function Home() {
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form">
          <div className="container">
            <div className="row ">
              <div className="col-lg-1"></div>
              <div className="col-lg-6">
                <div className="card_login">
                  <h3>Forget Password</h3>
                  <div>
                    <div class="form_login_input">
                      <label>Enter New Password</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="New Password"
                      />
                    </div>
                    <div class="form_login_input">
                      <label>Confirm New Password</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Confirm New Password"
                      />
                    </div>

                    <div class="form_login_input">
                      <Button>Change Password</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
