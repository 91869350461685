import React, { useState } from 'react'
import Header from './Header'
import { Button } from '@mui/material'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import Footer from './Footer'
import apiService from '../core/service/detail'
import { postMethod } from '../core/service/common.api'
import { toast } from "react-toastify";
import {setAuthorization} from "../core/service/axios";

function Tfalogin() {

    const navigate = useNavigate();
    const  state  = localStorage.getItem("socket_token");
    const initialFormValue = {
        tfa: "",
    };

    const [formValue, setFormValue] = useState(initialFormValue);
    const [tfaValidate, settfaValidate] = useState(false);
    const [validationnErr, setvalidationnErr] = useState("");
    const [buttonLoader, setbuttonLoader] = useState(false)

    const { tfa } = formValue;
    const handleChange = async (e) => {
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } };
        setFormValue(formData);
        validate(formData);
    };
    const formSubmit = async () => {
        validate(formValue);
        console.log(state,"=-=-=state")
        if (formValue.tfa !== "") {
            var data = {
                apiUrl: apiService.tfaVerify,
                payload: {  
                    userToken: tfa,
                    socketToken: state
                },
            };
            setbuttonLoader(true)
            var resp = await postMethod(data);
            setbuttonLoader(false)
            console.log("tfa login===", resp);
            if (resp.status) {
                toast.success(resp.Message);
                console.log(resp.token)
                 setAuthorization(resp.token);
                localStorage.setItem("user_token", resp.token);
                localStorage.setItem("tfa_status", resp.tfa);
                localStorage.setItem("socket_token", resp.socketToken);
                navigate("/dashboard");
            } else {
                toast.error(resp.Message);
            }
        }
    };

    const validate = async (values) => {
        const errors = {};
        if (!values.tfa) {
            errors.tfa = "2FA is Required";
            settfaValidate(true);
        }
        setvalidationnErr(errors);
        return errors;
    };
    return (
        <div className="header ">
            <Header />
            <main>
                <div className="login_form">
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-6">
                                <div className="card_login">
                                    <h3>2FA Login</h3>
                                    <div>
                                        <div class="form_login_input">
                                            <label>2FA code</label>
                                            <input
                                                type="number"
                                                class="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Your 2FA"
                                                name="tfa"
                                                value={tfa}
                                                onChange={handleChange}
                                            />

                                            <div>
                                                {tfaValidate == true ? (
                                                    <p className="text-danger">
                                                        {" "}
                                                        {validationnErr.tfa}{" "}
                                                    </p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>

                                        <div class="form_login_input">
                                            {buttonLoader == false ? (
                                                <Button
                                                    onClick={formSubmit}
                                                >
                                                    Login
                                                </Button>
                                            ) : (
                                                <Button>
                                                    Loading...
                                                </Button>
                                            )}
                                        </div>
                                        <Link to="/forget" className="pass_forgee mt-3 d-block">
                                            Forget Password ?
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Tfalogin