import {React, useState, useEffect, useLayoutEffect} from "react";
import logo from "./logo.svg";
// import "./App.css";
import Home from "./pages/Home";
import {BrowserRouter, Routes, Route, useLocation} from "react-router-dom";
import Refferal from "./pages/Refferal";
import Applayform from "./pages/Applayform";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Forget from "./pages/Forget";
import ConfirmNewpass from "./pages/ConfirmNewpass";
import Dashboard from "./pages/Dashboard";
import Trade from "./pages/Trade";
import Commingsoon from "./pages/Commingsoon";
import Kyc from "./pages/Kyc";
import SessionHistory from "./pages/SesionHistory";
import SecurityNew from "./pages/SecurityNew";
import Editprofile from "./pages/Editprofile";
import Deposit from "./pages/Deposit";
import Withdrew from "./pages/Withdrew";
import Swap from "./pages/Swap";
import P2p from "./pages/P2p";
import Chatp2p from "./pages/Chatp2p";
import PostAd from "./pages/PostAd";
import Markets from "./pages/Markets";
import Bankdetails from "./pages/Bankdetails";
import P2pView from "./pages/P2pView";
import P2PprocessOrders from "./pages/P2PprocessOrders";
import P2PmyOrders from "./pages/P2PmyOrders";
import P2PconfirmOrders from "./pages/P2PconfirmOrders";
import Tradedemo from "./pages/Trade_demo";
import Staking from "./pages/Staking";
import Marchant from "./pages/Marchant";
import Widgets from "./pages/widgets";

// import Dashboard from "./pages/Dashboard" ;
import Otp from "./pages/Otp";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {removeAuthToken} from "../src/core/lib/localStorage";
import Tfalogin from "./pages/Tfalogin";
import IdleTimeOutHandler from "./pages/IdleTimeOutHandler";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "./pages/ScrollToTop";
import UnderMaintenance from "./pages/UnderMaintenance";
import Chart from "./pages/Chart";
import Privacy from "./pages/Privacy";
import Faq from "./pages/Faq";
import Aboutus from "./pages/Aboutus";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  function RequireAuth({children}) {
    var data = localStorage.getItem("user_token");
    return data ? children : removeAuthToken();
  }

  const [isActive, setIsActive] = useState(true);

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <IdleTimeOutHandler
          onActive={() => {
            setIsActive(true);
          }}
          onIdle={() => {
            setIsActive(false);
          }}
        />
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/" element={<UnderMaintenance/>} /> */}
          <Route path="/trade_demo" element={<Tradedemo />} />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forget/:id" element={<Forget />} />
          <Route path="/forget" element={<Forget />} />
          <Route path="/tfalogin" element={<Tfalogin />} />
          <Route path="/newpass" element={<ConfirmNewpass />} />
          <Route path="/swap" element={<Swap />} />
          <Route path="/p2p" element={<P2p />} />
          <Route
            path="/p2p/chat/:id"
            element={
              <RequireAuth>
                <Chatp2p />
              </RequireAuth>
            }
          />
          <Route
            path="/p2p/postAd"
            element={
              <RequireAuth>
                <PostAd />
              </RequireAuth>
            }
          />
          <Route
            path="/refferal"
            element={
              <RequireAuth>
                <Refferal />
              </RequireAuth>
            }
          />
          <Route
            path="/p2p/view/:id"
            element={
              <RequireAuth>
                <P2pView />
              </RequireAuth>
            }
          />
          <Route path="/markets" element={<Markets />} />

          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                {" "}
                <Dashboard />{" "}
              </RequireAuth>
            }
          />
          <Route path="/sessionHistory" element={<SessionHistory />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/trade/:pair" element={<Trade />} />
          <Route path="/soon" element={<Commingsoon />} />
          <Route
            path="/Kyc"
            element={
              <RequireAuth>
                <Kyc />
              </RequireAuth>
            }
          />
          <Route
            path="/security"
            element={
              <RequireAuth>
                <SecurityNew />
              </RequireAuth>
            }
          />
          <Route
            path="/Dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/editprofile"
            element={
              <RequireAuth>
                <Editprofile />
              </RequireAuth>
            }
          />
          <Route
            path="/deposit"
            element={
              <RequireAuth>
                <Deposit />
              </RequireAuth>
            }
          />
          <Route
            path="/withdraw"
            element={
              <RequireAuth>
                <Withdrew />
              </RequireAuth>
            }
          />

          <Route
            path="/bankdetails"
            element={
              <RequireAuth>
                <Bankdetails />
              </RequireAuth>
            }
          />

          <Route
            path="/widgets"
            element={
              <RequireAuth>
                <Widgets />
              </RequireAuth>
            }
          />
          <Route
            path="/merchant"
            element={
              <RequireAuth>
                <Marchant />
              </RequireAuth>
            }
          />
          <Route
            path="/staking"
            element={
              <RequireAuth>
                <Staking />
              </RequireAuth>
            }
          />
          <Route
            path="/p2p-processOrders"
            element={
              <RequireAuth>
                <P2PprocessOrders />
              </RequireAuth>
            }
          />

          <Route
            path="/p2p-Orders"
            element={
              <RequireAuth>
                {" "}
                <P2PmyOrders />{" "}
              </RequireAuth>
            }
          />

          <Route
            path="/p2p-History"
            element={
              <RequireAuth>
                {" "}
                <P2PconfirmOrders />{" "}
              </RequireAuth>
            }
          />

          <Route path="/tradeview/:pair" element={<Chart />} />

          <Route path="/privacy-policy" element={<Privacy />} />

          <Route path="/faq" element={<Faq />} />

          <Route path="/about-us" element={<Aboutus />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
