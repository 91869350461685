import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import Moment from "moment";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import Sidebar from "./Sidebarlink";
import moment from "moment";

function Home() {
  const [loader, setLoader] = useState(false);
  // fixed staking
  const [perpage, setperpage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [stakeDetails, setstakeDetails] = useState([]);
  const [allstakeDetails, setallstakeDetails] = useState([]);
  const [totalactive, settotalactive] = useState(0);
  const recordPerPage = 10;
  const pageRange = 10;

  // flexible staking
  const [perpage_flexible, setperpage_flexible] = useState(10);
  const [currentPage_flexible, setCurrentPage_flexible] = useState(1);
  const [stakeDetails_flexible, setstakeDetails_flexible] = useState([]);
  const [allstakeDetails_flexible, setallstakeDetails_flexible] = useState([]);
  const [totalactive_flexible, settotalactive_flexible] = useState(0);
  const recordPerPage_flexible = 10;
  const pageRange_flexbile = 10;

  // yield staking
  const [perpage_yield, setperpage_yield] = useState(10);
  const [currentPage_yield, setCurrentPage_yield] = useState(1);
  const [stakeDetails_yield, setstakeDetails_yield] = useState([]);
  const [allstakeDetails_yield, setallstakeDetails_yield] = useState([]);
  const [totalactive_yield, settotalactive_yield] = useState(0);
  const recordPerPage_yield = 10;
  const pageRange_yield = 10;

  // staking history

  const [perpage_history, setperpage_history] = useState(10);
  const [currentPage_history, setCurrentPage_history] = useState(1);
  const [totalactive_history, settotalactive_history] = useState(0);
  const recordPerPage_history = 10;
  const pageRange_history = 10;

  const [staking1, setstaking1, staking1ref] = useState("");
  const [staking1_popular, setstaking1_popular, staking1_popularref] = useState("");
  const [staking2, setstaking2] = useState("");
  const [staking3, setstaking3] = useState("");
  const [staking4, setstaking4] = useState("");

  const [userBalance, setuserBalance] = useState([]);
  const [currentPack, setcurrentPack] = useState("");
  const [stakeBalance, setStakeBalance] = useState("");
  const [fromDates, setfromData] = useState("");
  const [toDates, settoDate] = useState("");
  const [apy, setAPY] = useState(0);
  const [apr, setAPR] = useState(0);
  const [FlexibleEarn, setFlexibleEarn] = useState(0);

  const [authToken, setauthToken] = useState(false);
  const [stakeValue, setstakeValue] = useState(0);
  const [currentDuration, setcurrentDuration] = useState(0);
  const [currentDurationFlex, setcurrentDurationFlex] = useState(0);
  const [TotalFlexible, setTotalFlexible] = useState(0);

  const [userTotlaInterest, setuserTotlaInterest] = useState(0);
  const [usetDailyInterest, setusetDailyInterest] = useState(0);
  const [stakeHistory, setstakeHistory, stakeHistoryref] = useState([]);
  const [allstakeHistory, setallstakeHistory, allstakeHistoryref] = useState([]);
  const [validation, setValidation] = useState(false);
  const [validationErr, setValidationErr] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [fixedvalue, setFixedValue] = useState(4);
  const [totalStakedValue, settotalStakedValue] = useState(0);
  const [totallockedValue, settotallockedValue] = useState(0);
  const [totalStakedValueINR, settotalStakedValueINR] = useState(0);
  const [totallockedValueINR, settotallockedValueINR] = useState(0);
  const [stakedcurrency, setStakedCurrency] = useState(0);
  const [interest, setInterest] = useState(0);
  const [stakingType, setstakingType] = useState("fixed");
  const [selectedFlex, setselectedFlex] = useState("");
  const [adminProfit, setAdminprofit] = useState(0);
  const [flexibleStartDate, setflexibleStartDate] = useState("");
  const [flexibleEndDate, setflexibleEndDate] = useState("");

  const [yieldDuration, setyieldDuration] = useState(0);
  const [yiledSelectDur, setyiledSelectDur] = useState("");

  const [yieldID, setyieldID] = useState("");
  const [yieldData, setYieldData] = useState("");
  const [currentYieldYear, setcurrentYieldYear] = useState(365);
  const [yieldAPY, setyieldAPY] = useState(0);
  const [yiledToDate, setyiledToDate] = useState("");
  const [yiledStakeType, setyiledStakeType] = useState(0);
  const [yiledDays, setYiledDays] = useState(0);
  const [YieldEstimation, setYieldEstimation] = useState(0);
  const [YieldValue, setYieldValue, YieldValueref] = useState(0);

  const [YieldFirst, setYieldFirst] = useState(0);
  const [YieldSecond, setYieldSecond] = useState(0);
  const [YieldThird, setYieldThird] = useState(0);
  const [YieldFourth, setYieldFourth] = useState(0);


  const [stake_type, set_type, stake_typeref] = useState("");

  const [fixed_active1, setfixed_active1, fixed_active1ref] = useState(false);
  const [fixed_active2, setfixed_active2, fixed_active2ref] = useState(false);
  const [fixed_active3, setfixed_active3, fixed_active3ref] = useState(false);
  const [fixed_active4, setfixed_active4, fixed_active4ref] = useState(false);

  const [flexible_active, setflexible_active, flexible_activeref] = useState(false);
  const [yield_active, setyield_active, yield_activeref] = useState(false);
  const [fixed_popular, setfixed_popular, fixed_popularref] = useState({});
  const [flexible_popular, setflexible_popular, flexible_popularref] = useState({});
  const [yield_popular, setyield_popular, yield_popularref] = useState({});



  useEffect(() => {
    getBalance();
    getStakeDetails_fixed(1);
    getStakeDetails_flexible(1);
    getStakeDetails_yield(1);
    getStakingHistory(1);
    getTodayDate();
    getstake_popular();
  }, [0]);

  const getStakeDetails_fixed = async (pages) => {
    var obj = {
      FilPerpage: perpage,
      FilPage: pages,
    };
    var data = {
      apiUrl: apiService.statkingDetails_fixed,
      payload: obj,
    };
    setLoader(true)
    var resp = await postMethod(data);
    setLoader(false)
    if (resp.status) {
      var datas = resp.data.result;
      settotalactive(resp.data.count);
      // console.log(datas, "=-=-=-=-data");
      setstakeDetails(datas);
      setallstakeDetails(datas);
    } else {
      setstakeDetails([]);
      setallstakeDetails([]);
    }
  };

  const getStakeDetails_flexible = async (pages) => {
    var obj = {
      FilPerpage: perpage,
      FilPage: pages,
    };
    var data = {
      apiUrl: apiService.statkingDetails_flexible,
      payload: obj,
    };
    setLoader(true)
    var resp = await postMethod(data);
    setLoader(false)
    if (resp.status) {
      var datas = resp.data.result;
      settotalactive_flexible(resp.data.count);
      //console.log(datas, "=-=-=-=-data");
      setstakeDetails_flexible(datas);
      setallstakeDetails_flexible(datas);
    } else {
      setstakeDetails_flexible([]);
      setallstakeDetails_flexible([]);
    }
  };

  const getStakeDetails_yield = async (pages) => {
    var obj = {
      FilPerpage: perpage,
      FilPage: pages,
    };
    var data = {
      apiUrl: apiService.statkingDetails_yield,
      payload: obj,
    };
    setLoader(true)
    var resp = await postMethod(data);
    setLoader(false)
    if (resp.status) {
      var datas = resp.data.result;
      settotalactive_yield(resp.data.count);
      console.log(datas, "=-=-=-=-yield datas");
      setstakeDetails_yield(datas);
      setallstakeDetails_yield(datas);
    } else {
      setstakeDetails_yield([]);
      setallstakeDetails_yield([]);
    }
  };

  const activePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    getStakeDetails_fixed(pageNumber);
  };

  const activePageChange_flexible = (pageNumber) => {
    setCurrentPage_flexible(pageNumber); // call API to get data based on pageNumber
    getStakeDetails_flexible(pageNumber);
  };

  const activePageChange_yield = (pageNumber) => {
    setCurrentPage_yield(pageNumber); // call API to get data based on pageNumber
    getStakeDetails_yield(pageNumber);
  };


  const activePageChange_history = (pageNumber) => {
    setCurrentPage_history(pageNumber); // call API to get data based on pageNumber
    getStakingHistory(pageNumber);
  };


  const chooseDuration = async (selectedduration, data, duration) => {
    try {
      setcurrentDuration(+duration);
      if (selectedduration == "stake1") {
        console.log("call stake1")
        setAPY(data.FistDurationAPY);
        setfixed_active2(false);
        setfixed_active3(false);
        setfixed_active4(false);
        setfixed_active1(true);
      } else if (selectedduration == "stake2") {
        console.log("call stake2")
        setAPY(data.SecondDurationAPY);
        setfixed_active1(false);
        setfixed_active3(false);
        setfixed_active4(false);
        setfixed_active2(true);
      } else if (selectedduration == "stake3") {
        console.log("call stake3")
        setAPY(data.ThirdDurationAPY);
        setfixed_active1(false);
        setfixed_active2(false);
        setfixed_active4(false);
        setfixed_active3(true);
      } else if (selectedduration == "stake4") {
        console.log("call stake4")
        setAPY(data.FourthDurationAPY);
        setfixed_active1(false);
        setfixed_active2(false);
        setfixed_active3(false);
        setfixed_active4(true);
      } else {
        console.log("call stake0")
        setAPY(0);
        setfixed_active1(false);
        setfixed_active2(false);
        setfixed_active3(false);
        setfixed_active4(false);
      }
      var obj = {
        status: selectedduration,
        id: data._id,
      };

      setstaking1(obj);
      console.log("stake id====", staking1ref.current.id)

      dataCalculation(selectedduration, data, duration);
    } catch (error) { }
  };

  const chooseDuration_popular = async (selectedduration, data, duration) => {
    try {
      setcurrentDuration(+duration);
      if (selectedduration == "stake1") {
        console.log("call stake1")
        setAPY(data.FistDurationAPY);
        setfixed_active2(false);
        setfixed_active3(false);
        setfixed_active4(false);
        setfixed_active1(true);
      } else if (selectedduration == "stake2") {
        console.log("call stake2")
        setAPY(data.SecondDurationAPY);
        setfixed_active1(false);
        setfixed_active3(false);
        setfixed_active4(false);
        setfixed_active2(true);
      } else if (selectedduration == "stake3") {
        console.log("call stake3")
        setAPY(data.ThirdDurationAPY);
        setfixed_active1(false);
        setfixed_active2(false);
        setfixed_active4(false);
        setfixed_active3(true);
      } else if (selectedduration == "stake4") {
        console.log("call stake4")
        setAPY(data.FourthDurationAPY);
        setfixed_active1(false);
        setfixed_active2(false);
        setfixed_active3(false);
        setfixed_active4(true);
      } else {
        console.log("call stake0")
        setAPY(0);
        setfixed_active1(false);
        setfixed_active2(false);
        setfixed_active3(false);
        setfixed_active4(false);
      }
      var obj = {
        status: selectedduration,
        id: data._id,
      };

      setstaking1_popular(obj);
      console.log("staking1_popular===", staking1_popularref.current)

      dataCalculation(selectedduration, data, duration);
    } catch (error) { }
  };

  const dataCalculation = async (stakePosition, statkeDetails, duration) => {
    try {
      var plans = parseFloat(duration);
      var d = new Date();
      var fromDate =
        (await d.getDate()) +
        "/" +
        (d.getMonth() + 1) +
        "/" +
        d.getFullYear() +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes();
      setfromData(fromDate);

      var myDate = new Date(new Date().getTime() + plans * 24 * 60 * 60 * 1000);

      var toDate =
        (await myDate.getDate()) +
        "/" +
        (myDate.getMonth() + 1) +
        "/" +
        myDate.getFullYear() +
        " " +
        myDate.getHours() +
        ":" +
        myDate.getMinutes();
      if (stakingType == "yield") {
        setyiledToDate(toDate);
      } else {
        settoDate(toDate);
      }
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const getBalance = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserBalanceAll,
      };
      setLoader(true)
      var resp = await getMethod(data);
      setLoader(false)
      if (resp.status) {
        setuserBalance(resp.data);
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const stakeNow = (currentPack, type) => {
    console.log("staking1ref.current===", staking1ref.current);
    try {
      setstakeValue(0);
      setuserTotlaInterest(0);
      setFlexibleEarn(0);
      setInterest(currentPack.APRinterest);
      setstakingType(type);
      console.log(currentPack);
      setcurrentPack(currentPack);
      var index = userBalance.findIndex(
        (x) => x.currencySymbol == currentPack.currencySymbol
      );
      if (index != -1) {
        let findCurrency = userBalance[index];
        setStakeBalance(findCurrency);
      }
      var obj = {
        status: "",
        id: currentPack._id,
      };
      setstaking2(obj);
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const choosePlan = async () => {
    try {
      toast.error("Please choose duration");
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const maximum = async () => {
    try {
      setstakeValue(
        parseFloat(stakeBalance.currencyBalance).toFixed(fixedvalue)
      );
      var amount = parseFloat(stakeBalance.currencyBalance);
      console.log(amount, "9090");
      console.log(apy, "apy");
      console.log(currentDuration, "currentDuration");
      if (amount > 0) {
        if (stakingType == "fixed") {
          var dailyinterest = amount * (+apy / 100 / 365);
          var totalInterest = parseFloat(dailyinterest) * currentDuration;
          setuserTotlaInterest(totalInterest);
          setusetDailyInterest(dailyinterest);
        }
        else if (stakingType == "flexible") {
          var dailyinterestDate = amount * (+interest / 100 / 365);
          var totalInterestFlex = parseFloat(dailyinterestDate) * 1;
          setFlexibleEarn(dailyinterestDate);
          setTotalFlexible(totalInterestFlex);
        }
      } else {
        toast.error("Insufficient balance");
      }
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const stakeAmount = (e) => {
    try {
      setstakeValue(e.target.value);
      setYieldValue(e.target.value);
      var amount = parseFloat(e.target.value);
      if (stakingType == "fixed") {
        var dailyinterest = amount * (+apy / 100 / 365);
        var totalInterest = parseFloat(dailyinterest) * currentDuration;
        setuserTotlaInterest(totalInterest);
        setusetDailyInterest(dailyinterest);
      } else if (stakingType == "flexible") {
        console.log("amount::::", amount, "interest:::", interest);
        var dailyinterestDate = amount * (+interest / 100 / 365);
        var totalInterestFlex = parseFloat(dailyinterestDate) * 1;
        setFlexibleEarn(dailyinterestDate);
        setTotalFlexible(totalInterestFlex);
      } else if (stakingType == "yield") {
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };


  const confirmStack = async () => {
    try {
      if (stakeValue > 0) {
        setValidation(false);
        if (stakingType == "fixed") {
          var obj = {
            stakingPlan: currentDuration,
            totalInterest: userTotlaInterest,
            dailyinterest: usetDailyInterest,
            startDate: fromDates,
            endDate: toDates,
            currentAPY: apy,
            stakeMore: staking1,
            stakeAmont: stakeValue,
            type: "fixed",
          };
        } else if (stakingType == "yield") {
          console.log("****stakingTypeyieldyieldstakingType*****8");
          var plan = {
            id: currentPack._id,
            stats: "statke1",
          };
          var obj = {
            stakingPlan: yiledSelectDur,
            totalInterest: YieldEstimation,
            dailyinterest: 0,
            startDate: fromDates,
            endDate: yiledToDate,
            currentAPY: yieldAPY,
            stakeMore: plan,
            stakeAmont: +YieldValueref.current,
            type: "yield",
          };
        } else {
          var obj = {
            //stakingPlan: currentDurationFlex,
            stakingPlan: 0,
            totalInterest: TotalFlexible,
            dailyinterest: FlexibleEarn,
            startDate: fromDates,
            endDate: flexibleEndDate,
            currentAPY: interest,
            stakeMore: staking2,
            stakeAmont: stakeValue,
            type: "flexible",
          };
        }
        var data = {
          apiUrl: apiService.confirmStaking,
          payload: obj,
        };
        setButtonLoader(true);
        // return false;
        var resp = await postMethod(data);

        setButtonLoader(false);
        if (resp.status) {
          await toast.success(resp.Message);
          window.location.reload();
        } else {
          toast.error(resp.Message);
        }
      } else {
        setValidation(true);
        toast.error("Enter stake amount");
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const getStakingHistory = async (pages) => {
    try {
      var data = {
        apiUrl: apiService.getAllstakingHistory,
        payload: {
          type: stake_typeref.current,
          FilPerpage: perpage,
          FilPage: pages
        },
      };
      var resp = await postMethod(data);
      if (resp.status) {
        // setuserBalance(resp.data);
        console.log(resp.data, "=-=-resp-=-=-=resp-=-resp.data");
        setstakeHistory(resp.data.result);
        setallstakeHistory(resp.data.result);
        settotalactive_history(resp.data.count);
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const claimNow = async (claimData) => {
    try {
      var obj = {
        _id: claimData._id,
      };
      var data = {
        apiUrl: apiService.claimNowapi,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getStakingHistory(1);
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const claimNowFlexible = async (claimData) => {
    try {
      var obj = {
        _id: claimData._id,
      };
      var data = {
        apiUrl: apiService.claimNowapiFlexible,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getStakingHistory(1);
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const claimNow_yield = async (claimData) => {
    try {
      var obj = {
        _id: claimData._id,
      };
      var data = {
        apiUrl: apiService.claimNow_yield,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getStakingHistory(1);
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };


  const getTodayDate = async () => {
    var d = new Date();
    var fromDate =
      (await d.getDate()) +
      "/" +
      (d.getMonth() + 1) +
      "/" +
      d.getFullYear() +
      " " +
      d.getHours() +
      ":" +
      d.getMinutes();
    setfromData(fromDate);
    setflexibleStartDate(fromDate);

    var myDate = new Date(d.getTime() + 365 * 24 * 60 * 60 * 1000);

    var toDate =
      (await myDate.getDate()) +
      "/" +
      (myDate.getMonth() + 1) +
      "/" +
      myDate.getFullYear() +
      " " +
      myDate.getHours() +
      ":" +
      myDate.getMinutes();
    setflexibleEndDate(toDate);
  };

  const yieldYear = async (yearType) => {
    try {
      var year =
        yearType == "oneyear" ? 365 : yearType == "threeyear" ? 1095 : 1825;
      setcurrentYieldYear(year);
      var statkeType =
        yearType == "oneyear" ? 1 : yearType == "threeyear" ? 3 : 5;
      setyiledStakeType(statkeType);
      console.log(year, "==-=year=-=-=year=-=-=-year");
      // dataCalculation("","",year);
      var myDate = new Date(new Date().getTime() + year * 24 * 60 * 60 * 1000);
      console.log(myDate, "==-=-myDate--=-=");
      var toDate =
        (await myDate.getDate()) +
        "/" +
        (myDate.getMonth() + 1) +
        "/" +
        myDate.getFullYear() +
        " " +
        myDate.getHours() +
        ":" +
        myDate.getMinutes();
      console.log(toDate, "=-=-=-=-===-==-=-=toDate==-=-=-==-=-=--=");
      setyiledToDate(toDate);
    } catch (error) { }
  };

  const chooseDurationYield = async (selectedduration, data) => {
    try {
      console.log("chooseDurationYield===", selectedduration)
      console.log("data===", data)
      if (yiledStakeType == 1) {
        if (selectedduration == "stake1") {
          setyieldAPY(data.yiledAPR_1_firstDuration);
          setyieldDuration(data.yiled_1_firstDuration);
        } else if (selectedduration == "stake2") {
          setyieldAPY(data.yiledAPR_1_secondDuration);
          setyieldDuration(data.yiled_1_secondDuration);
        } else if (selectedduration == "stake3") {
          setyieldAPY(data.yiledAPR_1_thirdDuration);
          setyieldDuration(data.yiled_1_thirdDuration);
        } else if (selectedduration == "stake4") {
          setyieldAPY(data.yiledAPR_1_fourthDuration);
          setyieldDuration(data.yiled_1_fourthDuration);
        } else {
          setyieldAPY(0);
        }
      } else if (yiledStakeType == 3) {
        if (selectedduration == "stake1") {
          setyieldAPY(data.yiledAPR_3_firstDuration);
          setyieldDuration(data.yiled_3_firstDuration);
        } else if (selectedduration == "stake2") {
          setyieldAPY(data.yiledAPR_3_secondDuration);
          setyieldDuration(data.yiled_3_secondDuration);
        } else if (selectedduration == "stake3") {
          setyieldAPY(data.yiledAPR_3_thirdDuration);
          setyieldDuration(data.yiled_3_thirdDuration);
        } else if (selectedduration == "stake4") {
          setyieldAPY(data.yiledAPR_3_fourthDuration);
          setyieldDuration(data.yiled_3_fourthDuration);
        } else {
          setyieldAPY(0);
        }
      } else if (yiledStakeType == 5) {
        if (selectedduration == "stake1") {
          setyieldAPY(data.yiledAPR_5_firstDuration);
          setyieldDuration(data.yiled_5_firstDuration);
        } else if (selectedduration == "stake2") {
          setyieldAPY(data.yiledAPR_5_secondDuration);
          setyieldDuration(data.yiled_5_secondDuration);
        } else if (selectedduration == "stake3") {
          setyieldAPY(data.yiledAPR_5_thirdDuration);
          setyieldDuration(data.yiled_5_thirdDuration);
        } else if (selectedduration == "stake4") {
          setyieldAPY(data.yiledAPR_5_fourthDuration);
          setyieldDuration(data.yiled_5_fourthDuration);
        } else {
          setyieldAPY(0);
        }
      }
      else {
        if (selectedduration == "stake1") {
          setyieldAPY(data.yiledAPR_1_firstDuration);
          setyieldDuration(data.yiled_1_firstDuration);
        } else if (selectedduration == "stake2") {
          setyieldAPY(data.yiledAPR_1_secondDuration);
          setyieldDuration(data.yiled_1_secondDuration);
        } else if (selectedduration == "stake3") {
          setyieldAPY(data.yiledAPR_1_thirdDuration);
          setyieldDuration(data.yiled_1_thirdDuration);
        } else if (selectedduration == "stake4") {
          setyieldAPY(data.yiledAPR_1_fourthDuration);
          setyieldDuration(data.yiled_1_fourthDuration);
        } else {
          setyieldAPY(0);
        }
      }

      setyieldID(data._id);
      setYieldData(data);
    } catch (error) { }
  };


  const activity = async (activityType) => {
    console.log("]]]]]]]]]", "activityType", activityType);
    try {
      setstakingType(activityType)
      if (activityType == "yield") {
        yieldYear("oneyear");
      }
    } catch (error) { }
  };

  const yieldCalculate = async (e) => {

    try {
      setYieldValue(e.target.value);
      if (YieldValueref.current > 0) {
        var obj = {
          investValue: Number(YieldValueref.current),
          APRPercentage: yieldAPY,
          days: yieldDuration,
          yiledStakeType: yiledStakeType * 365,
        };
        var data = {
          apiUrl: apiService.yieldcalculation,
          payload: obj,
        };
        var resp = await postMethod(data);
        console.log(resp, "-=-=-=obj-=-=-=-=-");
        setYieldEstimation(resp.Message);
        if (resp.status) {
          // toast.success(resp.Message);
          // getStakingHistory();
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Please enter stake amount");
      }
    } catch (error) { }
  };

  const yieldCalculate_max = async (e) => {

    try {
      setYieldValue(
        parseFloat(stakeBalance.currencyBalance).toFixed(fixedvalue)
      );
      if (YieldValueref.current > 0) {
        var obj = {
          investValue: Number(YieldValueref.current),
          APRPercentage: yieldAPY,
          days: yieldDuration,
          yiledStakeType: yiledStakeType * 365,
        };
        var data = {
          apiUrl: apiService.yieldcalculation,
          payload: obj,
        };
        var resp = await postMethod(data);
        console.log(resp, "-=-=-=obj-=-=-=-=-");
        setYieldEstimation(resp.Message);
        if (resp.status) {
          // toast.success(resp.Message);
          // getStakingHistory();
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Please enter stake amount");
      }
    } catch (error) { }
  };



  const stakeNowYield = (currentPack, type) => {
    try {
      console.log(currentPack, "-=--currentPack=-=-=");
      var obj = {
        status: "",
        id: currentPack._id,
      };
      setstaking2(obj);
      console.log(obj, "===-status=--");
      // setstakeValue(0);
      // setuserTotlaInterest(0);
      // setFlexibleEarn(0);
      // setInterest(currentPack.APRinterest);
      // setstakingType(type);
      // console.log(currentPack);
      setcurrentPack(currentPack);
      setstakingType("yield");
      // if(yiledStakeType==1)
      // {
      //   setyieldAPY(currentPack.yiledAPRinterest_1);
      // }
      // else if(yiledStakeType==3)
      // {
      //   setyieldAPY(currentPack.yiledAPRinterest_3);
      // }
      // else if(yiledStakeType==5)
      // {
      //   setyieldAPY(currentPack.yiledAPRinterest_5);
      // }

      var index = userBalance.findIndex(
        (x) => x.currencySymbol == currentPack.currencySymbol
      );
      console.log(userBalance, index, "-=-=index-==-index-=-=-=index=-==");
      if (index != -1) {
        let findCurrency = userBalance[index];
        setStakeBalance(findCurrency);
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };


  const confirmStackYield = async () => {
    try {
      if (YieldValueref.current > 0) {
        setValidation(false);
        console.log("confirm staking");
        if (YieldEstimation > 0) {
          var plan = {
            id: currentPack._id,
            stats: "Yield Staking",
          };
          var obj = {
            stakingPlan: yiledSelectDur,
            totalInterest: YieldEstimation,
            dailyinterest: 0,
            startDate: fromDates,
            endDate: yiledToDate,
            currentAPY: yieldAPY,
            stakeMore: plan,
            stakeAmont: +YieldValueref.current,
            type: "yield",
            totalPlan: yiledStakeType,
            yieldDuration: yieldDuration,
            currentYieldYear: currentYieldYear,
          };
          console.log(obj, "yield =-=-=obj-=-=");
          var data = {
            apiUrl: apiService.confirmStaking,
            payload: obj,
          };
          setButtonLoader(true);
          // return false;
          var resp = await postMethod(data);

          setButtonLoader(false);
          if (resp.status) {
            await toast.success(resp.Message);
            window.location.reload();
          } else {
            toast.error(resp.Message);
          }
        } else {
          toast.error("Calculate your amount!");
        }
      } else {
        setValidation(true);
        toast.error("Enter stake amount");
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const getstake_popular = async () => {
    var data = {
      apiUrl: apiService.popular_staking
    };
    setLoader(true)
    var resp = await getMethod(data);
    setLoader(false)
    if (resp.status) {
      setfixed_popular(resp.response.popular_fixed)
      setflexible_popular(resp.response.popular_flexible);
      setyield_popular(resp.response.popular_yield)
      // if(fixed_popularref.current != null && Object.keys(fixed_popularref.current).length >0)
      // {
      //   chooseDuration_popular("stake1",fixed_popularref.current,fixed_popularref.current.firstDuration);
      // }

      if (yield_popularref.current != null && Object.keys(yield_popularref.current).length > 0) {
        setyiledStakeType(1);
        //chooseDurationYield("stake1",yield_popularref.current);
        yieldYear("oneyear");
      }

    }
  };


  async function handleInputChange(event) {
    var search_term = event.target.value;
    if (stakingType == "fixed") {
      setstakeDetails(
        allstakeDetails.filter(function (tag) {
          if (
            tag.currencySymbol
              .toLowerCase()
              .indexOf(search_term.toLowerCase()) >= 0 ||
            tag.currencySymbol
              .toLowerCase()
              .indexOf(search_term.toLowerCase()) >= 0
          ) {
            return tag;
          }
        })
      );

    } else if (stakingType == "flexible") {

      setstakeDetails_flexible(
        allstakeDetails_flexible.filter(function (tag) {
          if (
            tag.currencySymbol
              .toLowerCase()
              .indexOf(search_term.toLowerCase()) >= 0 ||
            tag.currencySymbol
              .toLowerCase()
              .indexOf(search_term.toLowerCase()) >= 0
          ) {
            return tag;
          }
        })
      );

    }
    else if (stakingType == "yield") {

      setstakeDetails_yield(
        allstakeDetails_yield.filter(function (tag) {
          if (
            tag.currencySymbol
              .toLowerCase()
              .indexOf(search_term.toLowerCase()) >= 0 ||
            tag.currencySymbol
              .toLowerCase()
              .indexOf(search_term.toLowerCase()) >= 0
          ) {
            return tag;
          }
        })
      );

    }
    else if (stakingType == "history") {
      setstakeHistory(
        allstakeHistoryref.current.filter(function (tag) {
          if (
            tag.stakeCurrencsymbol
              .toLowerCase()
              .indexOf(search_term.toLowerCase()) >= 0 ||
            tag.stakeCurrencsymbol
              .toLowerCase()
              .indexOf(search_term.toLowerCase()) >= 0
          ) {
            return tag;
          }
        })
      )
    }
  }



  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght">
              {loader == true ? (
                <div class="spinner css-ezm1fk">
                  <div class="css-1qoumk2"></div>
                  <div class="css-1v19680"></div>
                  <div class="css-1v90jie"></div>
                  <div class="css-19p0rvp"></div>
                </div>
              ) : (
                <>
                  <section className="deposut_section">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="headin_deposur_s">
                            <h3>
                              {/* <Link to="/dashboard">
                          <i class="ri-arrow-left-fill"></i>
                        </Link> */}
                              Crypto Staking
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="form-deposit ba_new_staking">
                    <div className="container-fluid">
                      {/* <div className="row">
                <div className="col-lg-4">
                  <div className="crypto_stakin_card_">
                        <div className="coni_secion">
                          <div className="coin_details_img">
                            <img src="https://res.cloudinary.com/taikonz-com/image/upload/v1664014169/owiqfwnjv432fhou1upw.png" className="cin_ing"/>
                          <div className="naem_coin">
                            <h3>BTC</h3>
                            <p>Bitcon</p>
                          </div>
                          </div>
                          <div className="days_coin">
                              <h3>360 days</h3>
                              <p>Period</p>
                          </div>
                          <div className="intrest_rate">
                            <h3 className="text-green">24.04%</h3>
                            <p>Rate</p>
                          </div>
                        </div>
                        <div className="choosr_butn">
                          <Button>Choose Plan</Button>
                        </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="crypto_stakin_card_">
                        <div className="coni_secion">
                          <div className="coin_details_img">
                            <img src="https://res.cloudinary.com/taikonz-com/image/upload/v1664014169/owiqfwnjv432fhou1upw.png" className="cin_ing"/>
                          <div className="naem_coin">
                            <h3>BTC</h3>
                            <p>Bitcon</p>
                          </div>
                          </div>
                          <div className="days_coin">
                              <h3>360 days</h3>
                              <p>Period</p>
                          </div>
                          <div className="intrest_rate">
                            <h3 className="text-green">24.04%</h3>
                            <p>Rate</p>
                          </div>
                        </div>
                        <div className="choosr_butn">
                          <Button>Choose Plan</Button>
                        </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="crypto_stakin_card_">
                        <div className="coni_secion">
                          <div className="coin_details_img">
                            <img src="https://res.cloudinary.com/taikonz-com/image/upload/v1664014169/owiqfwnjv432fhou1upw.png" className="cin_ing"/>
                          <div className="naem_coin">
                            <h3>BTC</h3>
                            <p>Bitcon</p>
                          </div>
                          </div>
                          <div className="days_coin">
                              <h3>360 days</h3>
                              <p>Period</p>
                          </div>
                          <div className="intrest_rate">
                            <h3 className="text-green">24.04%</h3>
                            <p>Rate</p>
                          </div>
                        </div>
                        <div className="choosr_butn">
                          <Button>Choose Plan</Button>
                        </div>
                  </div>
                </div>
                </div> */}

                      <div className="row">
                        {fixed_popularref.current != null && Object.keys(fixed_popularref.current).length > 0 ? (
                          <div className="col-lg-4">
                            <div className="crypto_stakin_card_">
                              <div className="coni_secion">
                                <div className="coin_details_img">
                                  <img src={fixed_popularref.current.currencyImage} className="cin_ing" />
                                  <div className="naem_coin">
                                    <h3>{fixed_popularref.current.currencySymbol}</h3>
                                    <p>{fixed_popularref.current.currencyName}</p>
                                  </div>
                                </div>
                                <div className="days_coin">
                                  <h3>{fixed_popularref.current.firstDuration} days</h3>
                                  <p>Duration</p>
                                </div>
                                <div className="intrest_rate">
                                  <h3 className="text-green">{fixed_popularref.current.FistDurationAPY}%</h3>
                                  <p>APY</p>
                                </div>
                              </div>
                              <div className="choosr_butn">
                                <Button data-toggle="modal" data-target="#myModal" onClick={() => stakeNow(fixed_popularref.current, "fixed")}>Stake Now</Button>
                              </div>
                            </div>
                          </div>
                        ) : ("")}
                        {flexible_popularref.current != null && Object.keys(flexible_popularref.current).length > 0 ? (
                          <div className="col-lg-4">
                            <div className="crypto_stakin_card_">
                              <div className="coni_secion">
                                <div className="coin_details_img">
                                  <img src={flexible_popularref.current.currencyImage} className="cin_ing" />
                                  <div className="naem_coin">
                                    <h3>{flexible_popularref.current.currencySymbol}</h3>
                                    <p>{flexible_popularref.current.currencySymbol}</p>
                                  </div>
                                </div>
                                <div className="days_coin">
                                  <p>Flexible</p>
                                </div>
                                <div className="intrest_rate">
                                  <h3 className="text-green">{flexible_popularref.current.APRinterest}%</h3>
                                  <p>APR</p>
                                </div>
                              </div>
                              <div className="choosr_butn">
                                <Button data-toggle="modal" data-target="#myModal" onClick={() => stakeNow(flexible_popularref.current, "flexible")}>Stake Now</Button>
                              </div>
                            </div>
                          </div>
                        ) : ("")}
                        {yield_popularref.current != null && Object.keys(yield_popularref.current).length > 0 ? (
                          <div className="col-lg-4">
                            <div className="crypto_stakin_card_">
                              <div className="coni_secion">
                                <div className="coin_details_img">
                                  <img src={yield_popularref.current.currencyImage} className="cin_ing" />
                                  <div className="naem_coin">
                                    <h3>{yield_popularref.current.currencySymbol}</h3>
                                    <p>{yield_popularref.current.currencyName}</p>
                                  </div>
                                </div>
                                <div className="days_coin">
                                  <h3>{yield_popularref.current.yiled_1_firstDuration} days (1 year plan)</h3>
                                  <p>Duration</p>
                                </div>
                                <div className="intrest_rate">
                                  <h3 className="text-green">{yield_popularref.current.yiledAPR_1_firstDuration}%</h3>
                                  <p>APY</p>
                                </div>
                              </div>
                              <div className="choosr_butn">
                                <Button data-toggle="modal" data-target="#stacknowYield"
                                  onClick={() =>
                                    stakeNowYield(
                                      yield_popularref.current,
                                      "yield"
                                    )
                                  }>Stake Now</Button>
                              </div>
                            </div>
                          </div>
                        ) : ("")}
                      </div>
                      <div className="row mt-5">
                        <div className="col-lg-12">
                          <div className="crypto_stakin_card_">
                            <div className="crypto_searn_yabs">
                              <ul class="nav nav-tabs">
                                <li class="active"><a data-toggle="tab" href="#home" className="active" onClick={() => activity("fixed")}>Fixed Staking</a></li>
                                <li><a data-toggle="tab" href="#menu1" onClick={() => activity("flexible")}>Flexible Staking</a></li>
                                <li><a data-toggle="tab" href="#menu2" onClick={() => activity("yield")}>Yield Staking</a></li>
                                <li><a data-toggle="tab" href="#history" onClick={() => activity("history")}>Staking History</a></li>
                              </ul>
                              <div className="search_box">
                                <i class="ri-search-line"></i>
                                {stakingType == "fixed" ? (
                                  <input type="text" placeholder="Search By Asset" onChange={handleInputChange} />
                                ) : stakingType == "flexible" ? (
                                  <input type="text" placeholder="Search By Asset" onChange={handleInputChange} />
                                ) : stakingType == "yield" ? (
                                  <input type="text" placeholder="Search By Asset" onChange={handleInputChange} />
                                ) : stakingType == "history" ? (
                                  <input type="text" placeholder="Search By Asset" onChange={handleInputChange} />
                                ) : ""}


                              </div>

                            </div>
                            <div class="tab-content">
                              <div id="home" class="tab-pane fade in active show">
                                <div className="action_bootrsebe_tanbel">
                                  <div className="table-responsive mt-4 history_table">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th>Asset </th>
                                          <th colspan="3">Duration</th>
                                          <th>APY</th>
                                          <th>Limit</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {/* <tr>
                                        <td><div className="coin_details_img table_new_staknig">
                            <img src="https://res.cloudinary.com/taikonz-com/image/upload/v1664014169/owiqfwnjv432fhou1upw.png" className="cin_ing"/>
                          <div className="naem_coin">
                            <h3>BTC</h3>
                            <p>Bitcon</p>
                          </div>
                          </div></td>
                          <td colspan="3">
                            <div className="form_date_selecr">
                            <ul class="nav nav-tabs">
  <li class="active"><a data-toggle="tab" href="#day0"><div className="name_date"><h4>10</h4>
  <span>Days</span></div></a></li>
  <li><a data-toggle="tab" href="#day1"><div className="name_date"><h4>20</h4>
  <span>Days</span></div></a></li>
  <li><a data-toggle="tab" href="#day2"><div className="name_date"><h4>30</h4>
  <span>Days</span></div></a></li>
  <li><a data-toggle="tab" href="#day3"><div className="name_date"><h4>90</h4>
  <span>Days</span></div></a></li>
  <li><a data-toggle="tab" href="#day4"><div className="name_date"><h4>180</h4>
  <span>Days</span></div></a></li>
  <li><a data-toggle="tab" href="#day5"><div className="name_date"><h4>360</h4>
  <span>Days</span></div></a></li>
</ul>
                            </div>
                          </td>
                          <td><p className="text-green">24.04%</p></td>
                          <td><p>50 - 600,000 USDT</p></td>
                          <td><Button className="choose_plan" data-toggle="modal" data-target="#myModal">Choose plan</Button></td>
                                      </tr> */}
                                        {stakeDetails.length > 0
                                          ? stakeDetails.map((item, i) => {
                                            return (
                                              <tr>
                                                <td><div className="coin_details_img table_new_staknig">
                                                  <img src={item.currencyImage} className="cin_ing" />
                                                  <div className="naem_coin">
                                                    <h3>{item.currencySymbol}</h3>
                                                    <p>{item.currencyName}</p>
                                                  </div>
                                                </div></td>
                                                <td colspan="3">
                                                  <div className="form_date_selecr">
                                                    <ul class="nav nav-tabs">
                                                      {staking1ref.current &&
                                                        staking1ref.current.status == "stake1" &&
                                                        staking1ref.current.id == item._id ? (
                                                        <li className={fixed_active1ref.current == true ? "active" : ""} onClick={() =>
                                                          chooseDuration(
                                                            "stake1",
                                                            item,
                                                            item.firstDuration
                                                          )
                                                        }><a data-toggle="tab" href="#day0"><div className="name_date"><h4>{item.firstDuration}</h4>
                                                          <span>Days</span></div></a></li>
                                                      ) : (
                                                        <li onClick={() =>
                                                          chooseDuration(
                                                            "stake1",
                                                            item,
                                                            item.firstDuration
                                                          )
                                                        }><a data-toggle="tab" href="#day0"><div className="name_date"><h4>{item.firstDuration}</h4>
                                                          <span>Days</span></div></a></li>
                                                      )}

                                                      {staking1ref.current &&
                                                        staking1ref.current.status == "stake2" &&
                                                        staking1ref.current.id == item._id ? (
                                                        <li className={fixed_active2ref.current == true ? "active" : ""} onClick={() =>
                                                          chooseDuration(
                                                            "stake2",
                                                            item,
                                                            item.secondDuration
                                                          )
                                                        }><a data-toggle="tab" href="#day1"><div className="name_date"><h4>{item.secondDuration}</h4>
                                                          <span>Days</span></div></a></li>
                                                      ) : (
                                                        <li onClick={() =>
                                                          chooseDuration(
                                                            "stake2",
                                                            item,
                                                            item.secondDuration
                                                          )
                                                        }><a data-toggle="tab" href="#day1"><div className="name_date"><h4>{item.secondDuration}</h4>
                                                          <span>Days</span></div></a></li>
                                                      )}


                                                      {staking1ref.current &&
                                                        staking1ref.current.status == "stake3" &&
                                                        staking1ref.current.id == item._id ? (
                                                        <li className={fixed_active3ref.current == true ? "active" : ""} onClick={() =>
                                                          chooseDuration(
                                                            "stake3",
                                                            item,
                                                            item.thirdDuration
                                                          )
                                                        }><a data-toggle="tab" href="#day2"><div className="name_date"><h4>{item.thirdDuration}</h4>
                                                          <span>Days</span></div></a></li>
                                                      ) : (
                                                        <li onClick={() =>
                                                          chooseDuration(
                                                            "stake3",
                                                            item,
                                                            item.thirdDuration
                                                          )
                                                        }><a data-toggle="tab" href="#day2"><div className="name_date"><h4>{item.thirdDuration}</h4>
                                                          <span>Days</span></div></a></li>
                                                      )}

                                                      {staking1ref.current &&
                                                        staking1ref.current.status == "stake4" &&
                                                        staking1ref.current.id == item._id ? (
                                                        <li className={fixed_active4ref.current == true ? "active" : ""} onClick={() =>
                                                          chooseDuration(
                                                            "stake4",
                                                            item,
                                                            item.fourthDuration
                                                          )
                                                        }><a data-toggle="tab" href="#day3"><div className="name_date"><h4>{item.fourthDuration}</h4>
                                                          <span>Days</span></div></a></li>
                                                      ) : (
                                                        <li onClick={() =>
                                                          chooseDuration(
                                                            "stake4",
                                                            item,
                                                            item.fourthDuration
                                                          )
                                                        }><a data-toggle="tab" href="#day3"><div className="name_date"><h4>{item.fourthDuration}</h4>
                                                          <span>Days</span></div></a></li>
                                                      )}
                                                    </ul>
                                                  </div>
                                                </td>
                                                {staking1ref.current &&
                                                  staking1ref.current.id == item._id ? (
                                                  <td><p className="text-green">{apy}%</p></td>
                                                ) : (
                                                  <td><p className="text-green">0%</p></td>
                                                )}
                                                <td><p>{item.minimumStaking} - {item.maximumStaking} {item.currencySymbol}</p></td>
                                                <td>
                                                  {staking1ref.current &&
                                                    staking1ref.current.id == item._id ? (
                                                    <Button className="choose_plan" data-toggle="modal" data-target="#myModal" onClick={() => stakeNow(item, "fixed")}>Stake Now</Button>
                                                  ) : (
                                                    <Button className="choose_plan" onClick={() => choosePlan(item)}>Stake Now</Button>
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          }) : 0
                                        }

                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                {stakeDetails && stakeDetails.length > 0 ? (
                                  <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={currentPage}
                                    itemsCountPerPage={recordPerPage}
                                    totalItemsCount={totalactive}
                                    pageRangeDisplayed={pageRange}
                                    onChange={activePageChange}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div id="menu1" class="tab-pane fade">
                                <div className="action_bootrsebe_tanbel">
                                  <div className="table-responsive mt-4 history_table">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th>Asset </th>
                                          <th>APR</th>
                                          <th>Limit</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {stakeDetails_flexible.length > 0
                                          ? stakeDetails_flexible.map((item, i) => {
                                            return (
                                              <tr>
                                                <td><div className="coin_details_img table_new_staknig">
                                                  <img src={item.currencyImage} className="cin_ing" />
                                                  <div className="naem_coin">
                                                    <h3>{item.currencySymbol}</h3>
                                                    <p>{item.currencyName}</p>
                                                  </div>
                                                </div></td>
                                                <td><p className="text-green">{item.APRinterest}%</p></td>
                                                <td><p>{item.minimumStakingflex} - {item.maximumStakingflex} {item.currencySymbol}</p></td>
                                                <td>
                                                  <Button className="choose_plan" data-toggle="modal" data-target="#myModal" onClick={() => stakeNow(item, "flexible")}>Stake Now</Button>
                                                </td>
                                              </tr>
                                            );
                                          }) : 0
                                        }

                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                {stakeDetails_flexible && stakeDetails_flexible.length > 0 ? (
                                  <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={currentPage_flexible}
                                    itemsCountPerPage={recordPerPage_flexible}
                                    totalItemsCount={totalactive_flexible}
                                    pageRangeDisplayed={pageRange_flexbile}
                                    onChange={activePageChange_flexible}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div id="menu2" class="tab-pane fade">
                                <div className="crypto_stakin_card_">
                                  <div className="crypto_searn_yabs yrstd_tabs">
                                    <ul class="nav nav-tabs">
                                      <li class="active"><a data-toggle="tab" href="#oneyear" className="active" onClick={() => yieldYear("oneyear")}>1 Year</a></li>
                                      <li><a data-toggle="tab" href="#oneyear" onClick={() => yieldYear("threeyear")}>3 Year</a></li>
                                      <li><a data-toggle="tab" href="#oneyear" onClick={() => yieldYear("fiveyear")}>5 Year</a></li>
                                    </ul>
                                  </div>
                                  <div id="oneyear" class="tab-pane fade in active show">
                                    <div className="action_bootrsebe_tanbel">
                                      <div className="table-responsive mt-4 history_table">
                                        <table className="table">
                                          <thead>
                                            <tr>
                                              <th>Asset </th>
                                              <th colspan="3">Duration</th>
                                              <th>APY</th>
                                              <th>Limit</th>
                                              <th>Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {stakeDetails_yield.length > 0
                                              ? stakeDetails_yield.map((item, i) => {
                                                return (
                                                  <tr>
                                                    <td><div className="coin_details_img table_new_staknig">
                                                      <img src={item.currencyImage} className="cin_ing" />
                                                      <div className="naem_coin">
                                                        <h3>{item.currencySymbol}</h3>
                                                        <p>{item.currencyName}</p>
                                                      </div>
                                                    </div></td>
                                                    <td colspan="3">
                                                      <div className="form_date_selecr">
                                                        <ul class="nav nav-tabs">
                                                          <li className={
                                                            yieldDuration ==
                                                              "stake1" &&
                                                              item._id == yieldID
                                                              ? "active"
                                                              : ""
                                                          }
                                                            onClick={() =>
                                                              chooseDurationYield(
                                                                "stake1",
                                                                item
                                                              )
                                                            }
                                                          ><a data-toggle="tab" href="#day0" className={
                                                            yieldDuration ==
                                                              "stake1" &&
                                                              item._id == yieldID
                                                              ? "active"
                                                              : ""
                                                          }><div className="name_date">
                                                                {item &&
                                                                  yiledStakeType == 1 ? (
                                                                  <h4>
                                                                    {
                                                                      item.yiled_1_firstDuration
                                                                    }
                                                                  </h4>
                                                                ) : yiledStakeType == 3 ? (
                                                                  <h4>
                                                                    {
                                                                      item.yiled_3_firstDuration
                                                                    }
                                                                  </h4>
                                                                ) : yiledStakeType == 5 ? (
                                                                  <h4>
                                                                    {
                                                                      item.yiled_5_firstDuration
                                                                    }
                                                                  </h4>
                                                                ) : (
                                                                  ""
                                                                )}
                                                                <span>Days</span></div></a></li>

                                                          <li className={
                                                            yieldDuration ==
                                                              "stake2" &&
                                                              item._id == yieldID
                                                              ? "active"
                                                              : ""
                                                          }
                                                            onClick={() =>
                                                              chooseDurationYield(
                                                                "stake2",
                                                                item
                                                              )
                                                            }
                                                          ><a data-toggle="tab" href="#day0" className={
                                                            yieldDuration ==
                                                              "stake2" &&
                                                              item._id == yieldID
                                                              ? "active"
                                                              : ""
                                                          }><div className="name_date">
                                                                {item &&
                                                                  yiledStakeType == 1 ? (
                                                                  <h4>
                                                                    {
                                                                      item.yiled_1_secondDuration
                                                                    }
                                                                  </h4>
                                                                ) : yiledStakeType == 3 ? (
                                                                  <h4>
                                                                    {
                                                                      item.yiled_3_secondDuration
                                                                    }
                                                                  </h4>
                                                                ) : yiledStakeType == 5 ? (
                                                                  <h4>
                                                                    {
                                                                      item.yiled_5_secondDuration
                                                                    }
                                                                  </h4>
                                                                ) : (
                                                                  ""
                                                                )}
                                                                <span>Days</span></div></a></li>


                                                          <li className={
                                                            yieldDuration ==
                                                              "stake3" &&
                                                              item._id == yieldID
                                                              ? "active"
                                                              : ""
                                                          }
                                                            onClick={() =>
                                                              chooseDurationYield(
                                                                "stake3",
                                                                item
                                                              )
                                                            }
                                                          ><a data-toggle="tab" href="#day0" className={
                                                            yieldDuration ==
                                                              "stake3" &&
                                                              item._id == yieldID
                                                              ? "active"
                                                              : ""
                                                          }><div className="name_date">
                                                                {item &&
                                                                  yiledStakeType == 1 ? (
                                                                  <h4>
                                                                    {
                                                                      item.yiled_1_thirdDuration
                                                                    }
                                                                  </h4>
                                                                ) : yiledStakeType == 3 ? (
                                                                  <h4>
                                                                    {
                                                                      item.yiled_3_thirdDuration
                                                                    }
                                                                  </h4>
                                                                ) : yiledStakeType == 5 ? (
                                                                  <h4>
                                                                    {
                                                                      item.yiled_5_thirdDuration
                                                                    }
                                                                  </h4>
                                                                ) : (
                                                                  ""
                                                                )}
                                                                <span>Days</span></div></a></li>

                                                          <li className={
                                                            yieldDuration ==
                                                              "stake4" &&
                                                              item._id == yieldID
                                                              ? "active"
                                                              : ""
                                                          }
                                                            onClick={() =>
                                                              chooseDurationYield(
                                                                "stake4",
                                                                item
                                                              )
                                                            }
                                                          ><a data-toggle="tab" href="#day0" className={
                                                            yieldDuration ==
                                                              "stake4" &&
                                                              item._id == yieldID
                                                              ? "active"
                                                              : ""
                                                          }><div className="name_date">
                                                                {item &&
                                                                  yiledStakeType == 1 ? (
                                                                  <h4>
                                                                    {
                                                                      item.yiled_1_fourthDuration
                                                                    }
                                                                  </h4>
                                                                ) : yiledStakeType == 3 ? (
                                                                  <h4>
                                                                    {
                                                                      item.yiled_3_fourthDuration
                                                                    }
                                                                  </h4>
                                                                ) : yiledStakeType == 5 ? (
                                                                  <h4>
                                                                    {
                                                                      item.yiled_5_fourthDuration
                                                                    }
                                                                  </h4>
                                                                ) : (
                                                                  ""
                                                                )}
                                                                <span>Days</span></div></a></li>
                                                        </ul>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      {yieldID == item._id ? (
                                                        <p className="text-green">
                                                          {yieldAPY} %{" "}
                                                        </p>
                                                      ) : (
                                                        <p className="text-green">
                                                          0 %{" "}
                                                        </p>
                                                      )}
                                                    </td>
                                                    <td><p>{item.minimumStaking} - {item.maximumStaking} {item.currencySymbol}</p></td>
                                                    <td>
                                                      {yieldID == item._id ? (
                                                        <Button className="choose_plan" data-toggle="modal" data-target="#stacknowYield"
                                                          onClick={() =>
                                                            stakeNowYield(
                                                              item,
                                                              "yield"
                                                            )
                                                          }>Stake Now</Button>
                                                      ) : (
                                                        <Button className="choose_plan" onClick={() => choosePlan(item)}>Stake Now</Button>
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              }) : 0
                                            }

                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    {stakeDetails_yield && stakeDetails_yield.length > 0 ? (
                                      <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={currentPage_yield}
                                        itemsCountPerPage={recordPerPage_yield}
                                        totalItemsCount={totalactive_yield}
                                        pageRangeDisplayed={pageRange_yield}
                                        onChange={activePageChange_yield}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div id="history" class="tab-pane fade">
                                <div className="action_bootrsebe_tanbel">
                                  <div className="table-responsive mt-4 history_table">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th>Package</th>
                                          <th>Total Amount</th>
                                          <th>APY / APR</th>
                                          <th>Stake Date</th>
                                          <th>Interest Cycle</th>
                                          <th>Type</th>
                                          <th>Stake End Date</th>
                                          <th>Next Claim date</th>
                                          <th>Estimated Interests</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {stakeHistoryref.current &&
                                          stakeHistoryref.current.length > 0 ? (
                                          stakeHistoryref.current.map((item, i) => {
                                            var startdate = moment(item.startDate).format(
                                              "DD/MM/YYYY"
                                            );
                                            var endtdate = moment(item.endDate).format(
                                              "DD/MM/YYYY"
                                            );
                                            //console.log("item.date==", item.date);

                                            var get_time = new Date(item.date).getTime();
                                            //console.log("get_time===", get_time);
                                            var interest_cycle =
                                              item.type == "fixed"
                                                ? item.stakingPlan
                                                : item.YieldDuration;
                                            // console.log(
                                            //   "interest_cycle===",
                                            //   interest_cycle
                                            // );
                                            var added_date =
                                              get_time +
                                              +interest_cycle * 24 * 60 * 60 * 1000;
                                            // console.log("added_date===", added_date);
                                            var claim_date = "";
                                            if (item.type == "fixed") {
                                              claim_date = item.endDate
                                            }
                                            else if (item.type == "yield") {
                                              claim_date = item.nextclaimDate;
                                            }
                                            else {
                                              claim_date = "-";
                                            }
                                            //console.log("claim_date===", claim_date);
                                            claim_date =
                                              moment(claim_date).format("DD/MM/YYYY");

                                            return (
                                              <tr>
                                                <td><div className="coin_details_img table_new_staknig">
                                                  <img src={item.currencyImage} className="cin_ing" />
                                                  <div className="naem_coin">
                                                    <h3>{item.stakeCurrencsymbol}</h3>
                                                  </div>
                                                </div></td>
                                                <td><p>{item.stakeAmont}{" "}{item.stakeCurrencsymbol}</p></td>
                                                <td>
                                                  <p>{item.currentAPY}%</p>
                                                </td>
                                                <td>
                                                  <p>{startdate}</p>
                                                </td>
                                                <td>
                                                  <p>
                                                    {item.type == "fixed"
                                                      ? item.stakingPlan + " days"
                                                      : ""}{" "}
                                                    {item.type == "yield"
                                                      ? item.YieldDuration + " days"
                                                      : ""}{" "}
                                                    {item.type == "flexible" ? "-" : ""}{" "}
                                                  </p>
                                                </td>
                                                <td>
                                                  <p>{item.type} </p>
                                                </td>
                                                <td>
                                                  <p>
                                                    {item.type == "fixed" ||
                                                      item.type == "yield"
                                                      ? endtdate
                                                      : "-"}
                                                  </p>
                                                </td>
                                                <td>
                                                  <p>
                                                    {item.type == "fixed" ||
                                                      item.type == "yield"
                                                      ? claim_date
                                                      : "-"}
                                                  </p>
                                                </td>
                                                <td>
                                                  {item.type == "fixed" ? (
                                                    <p>
                                                      {parseFloat(
                                                        item.totalInterest
                                                      ).toFixed(4)}{" "}
                                                      {item.stakeCurrencsymbol}
                                                    </p>
                                                  ) : item.type == "yield" ? (
                                                    <p>
                                                      {parseFloat(
                                                        item.dailyinterest
                                                      ).toFixed(8)}{" "}
                                                      {item.stakeCurrencsymbol}
                                                    </p>
                                                  ) : (
                                                    <p>
                                                      {parseFloat(
                                                        item.totalInterest
                                                      ).toFixed(8)}{" "}
                                                      {item.stakeCurrencsymbol}
                                                    </p>
                                                  )}
                                                </td>
                                                <td>
                                                  {item.type == "fixed" ? (
                                                    <>
                                                      {item.status == 1 ? (
                                                        <Button
                                                          className="choose_plan"
                                                          onClick={() => claimNow(item)}
                                                        >
                                                          Claim Now
                                                        </Button>
                                                      ) : item.status == 0 ? (
                                                        <Button className="choose_plan">
                                                          {" "}
                                                          Claim
                                                        </Button>
                                                      ) : (
                                                        <Button className="choose_plan">
                                                          {" "}
                                                          Claimed
                                                        </Button>
                                                      )}
                                                    </>
                                                  ) : item.type == "yield" ? (
                                                    <>
                                                      {item.status == 1 ? (
                                                        <Button
                                                          className="choose_plan"
                                                          onClick={() =>
                                                            claimNow_yield(item)
                                                          }
                                                        >
                                                          Claim Now
                                                        </Button>
                                                      ) : item.status == 0 ? (
                                                        <Button className="choose_plan">
                                                          {" "}
                                                          Claim
                                                        </Button>
                                                      ) : (
                                                        <Button className="choose_plan">
                                                          {" "}
                                                          Claimed
                                                        </Button>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {item.status == 0 ? (
                                                        <Button
                                                          className="choose_plan"
                                                          onClick={() =>
                                                            claimNowFlexible(item)
                                                          }
                                                        >
                                                          Claim Now
                                                        </Button>
                                                      ) : (
                                                        <Button className="choose_plan">
                                                          Claimed
                                                        </Button>
                                                      )}
                                                    </>
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          })
                                        ) : (
                                          <tr>
                                            <td colSpan="9">
                                              No Staking History Found!
                                            </td>
                                          </tr>
                                        )
                                        }

                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                {stakeHistoryref.current && stakeHistoryref.current.length > 0 ? (
                                  <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={currentPage_history}
                                    itemsCountPerPage={recordPerPage_history}
                                    totalItemsCount={totalactive_history}
                                    pageRangeDisplayed={pageRange_history}
                                    onChange={activePageChange_history}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                </>
              )}
            </main>
          </div>
        </div>
      </div>
      {/* <div class="modal" id="myModal">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">

      <div class="modal-header">
        <div className="row">
          <div className="col-lg-2 d-flex align-items-center">
          <div className="coin_details_img table_new_staknig">
                            <img src="https://res.cloudinary.com/taikonz-com/image/upload/v1664014169/owiqfwnjv432fhou1upw.png" className="cin_ing"/>
                          <div className="naem_coin">
                            <h3>BTC</h3>
                            <p>Bitcon</p>
                          </div>
                          </div>
          </div>
          <div className="col-lg-1 d-flex align-items-center">
            <p className="text-green">24.5%</p>
          </div>
          <div className="col-lg-9">
          <div className="form_date_selecr">
                            <ul class="nav nav-tabs">
  <li class="active"><a data-toggle="tab" href="#day0"><div className="name_date"><h4>10</h4>
  <span>Days</span></div></a></li>
  <li><a data-toggle="tab" href="#day1"><div className="name_date"><h4>20</h4>
  <span>Days</span></div></a></li>
  <li><a data-toggle="tab" href="#day2"><div className="name_date"><h4>30</h4>
  <span>Days</span></div></a></li>
  <li><a data-toggle="tab" href="#day3"><div className="name_date"><h4>90</h4>
  <span>Days</span></div></a></li>
  <li><a data-toggle="tab" href="#day4"><div className="name_date"><h4>180</h4>
  <span>Days</span></div></a></li>
  <li><a data-toggle="tab" href="#day5"><div className="name_date"><h4>360</h4>
  <span>Days</span></div></a></li>
</ul>
                            </div>
          </div>
        </div>
      </div>

      <div class="modal-body for_pading_">
       <div className="row">
       <div className="col-lg-6">
        <div className="form_staking">
          <h4>Summary</h4>
          <p><span>Stake Amount</span><span>Available Amount 0.000 BTC</span></p>
          <div className="input_form">
            <input type="text" placeholder="Enter Amount" />
            <Button>Max</Button>
          </div>
        </div>
       </div>
       <div className="col-lg-6">
       <div className="form_staking">
       <h4>Staking</h4>
          <p><span>Stake Date</span><span>15/09/23 22:21</span></p>
          <p><span>Interest End Date</span><span>16/09/23 22:21</span></p>
          <hr></hr>
          <p><span>APY</span><span>70.5%</span></p>
          <p><span>Estimated Interest</span><span>0.000 BTC</span></p>
          <button className="submit">Confirm</button>
       </div>

       </div>
       </div>

      </div>

      

    </div>
  </div>
</div> */}


      <div class="modal" id="myModal">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">

            <div class="modal-header">
              <div className="row">
                <div className="col-lg-2 d-flex align-items-center">
                  <div className="coin_details_img table_new_staknig">
                    <img src={currentPack.currencyImage} className="cin_ing" />
                    <div className="naem_coin">
                      <h3>{currentPack.currencySymbol}</h3>
                      <p>{currentPack.currencyName}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1 d-flex align-items-center">
                  <p className="text-green">{stakingType == "fixed" ? apy : interest}%</p>
                </div>
                {stakingType == "fixed" ? (
                  <div className="col-lg-9">
                    <div className="form_date_selecr">
                      <ul class="nav nav-tabs">
                        {staking1ref.current && staking1ref.current.status == "stake1" ? (
                          <li class="active"><a data-toggle="tab" href="#day0" class="active"><div className="name_date"><h4>{currentPack.firstDuration}</h4>
                            <span>Days</span></div></a></li>
                        ) : (
                          <li onClick={() =>
                            chooseDuration(
                              "stake1",
                              currentPack,
                              currentPack.firstDuration
                            )
                          }><a data-toggle="tab" href="#day0"><div className="name_date"><h4>{currentPack.firstDuration}</h4>
                            <span>Days</span></div></a></li>
                        )}
                        {staking1ref.current && staking1ref.current.status == "stake2" ? (
                          <li class="active"><a data-toggle="tab" href="#day1" class="active"><div className="name_date"><h4>{currentPack.secondDuration}</h4>
                            <span>Days</span></div></a></li>
                        ) : (
                          <li onClick={() =>
                            chooseDuration(
                              "stake2",
                              currentPack,
                              currentPack.secondDuration
                            )
                          }><a data-toggle="tab" href="#day1"><div className="name_date"><h4>{currentPack.secondDuration}</h4>
                            <span>Days</span></div></a></li>
                        )}

                        {staking1ref.current && staking1ref.current.status == "stake3" ? (
                          <li class="active"><a data-toggle="tab" href="#day2" class="active"><div className="name_date"><h4>{currentPack.thirdDuration}</h4>
                            <span>Days</span></div></a></li>
                        ) : (
                          <li onClick={() =>
                            chooseDuration(
                              "stake3",
                              currentPack,
                              currentPack.thirdDuration
                            )
                          }><a data-toggle="tab" href="#day2"><div className="name_date"><h4>{currentPack.thirdDuration}</h4>
                            <span>Days</span></div></a></li>
                        )}

                        {staking1ref.current && staking1ref.current.status == "stake4" ? (
                          <li class="active"><a data-toggle="tab" href="#day3" class="active"><div className="name_date"><h4>{currentPack.fourthDuration}</h4>
                            <span>Days</span></div></a></li>
                        ) : (
                          <li onClick={() =>
                            chooseDuration(
                              "stake4",
                              currentPack,
                              currentPack.fourthDuration
                            )
                          }><a data-toggle="tab" href="#day3"><div className="name_date"><h4>{currentPack.fourthDuration}</h4>
                            <span>Days</span></div></a></li>
                        )}
                      </ul>
                    </div>
                  </div>
                ) : ("")}
              </div>
            </div>

            <div class="modal-body for_pading_">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form_staking">
                    <h4>Summary</h4>
                    <p><span>Stake Amount</span><span>Available Amount {parseFloat(stakeBalance.currencyBalance).toFixed(
                      4
                    )} {currentPack.currencySymbol}</span></p>
                    <div className="input_form">
                      <input type="number" value={stakeValue} placeholder="Enter amount" onChange={stakeAmount} min="0" />
                      <Button onClick={maximum}>Max</Button>
                    </div>
                    <div>
                      {validation == true ? (
                        <p className="text-danger">
                          {" "}
                          Stake amount is required{" "}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form_staking">
                    <h4>Staking</h4>
                    <p><span>Stake Date</span><span>{stakingType == "fixed" ? fromDates : flexibleStartDate}</span></p>
                    <p><span>Interest End Date</span><span>{stakingType == "fixed" ? toDates : "-"}</span></p>
                    <hr></hr>
                    <p>
                      {stakingType == "fixed" ? (
                        <span>APY</span>
                      ) : (
                        <span>APR</span>
                      )}
                      <span>{stakingType == "fixed" ? apy : interest}%</span>
                    </p>
                    <p><span>Estimated Interest</span><span>{stakingType == "fixed" ? parseFloat(userTotlaInterest).toFixed(8) : parseFloat(FlexibleEarn).toFixed(8)} {stakeBalance.currencySymbol}</span></p>
                    {buttonLoader == false ? (
                      <button className="submit" onClick={confirmStack}>Confirm</button>
                    ) : (
                      <button>Loading...</button>
                    )}
                  </div>

                </div>
              </div>

            </div>



          </div>
        </div>
      </div>

      <div class="modal" id="stacknowYield">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">

            <div class="modal-header">
              <div className="row">
                <div className="col-lg-2 d-flex align-items-center">
                  <div className="coin_details_img table_new_staknig">
                    <img src={currentPack.currencyImage} className="cin_ing" />
                    <div className="naem_coin">
                      <h3>{currentPack.currencySymbol}</h3>
                      <p>{currentPack.currencyName}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1 d-flex align-items-center">
                  <p className="text-green">{yieldAPY}%</p>
                </div>

                <div className="col-lg-9">
                  <div className="form_date_selecr">
                    <ul class="nav nav-tabs">
                      <li className={
                        yieldDuration ==
                          "stake1" &&
                          currentPack._id == yieldID
                          ? "active"
                          : ""
                      }
                        onClick={() =>
                          chooseDurationYield(
                            "stake1",
                            currentPack
                          )
                        }
                      ><a data-toggle="tab" href="#day0" className={
                        yieldDuration ==
                          "stake1" &&
                          currentPack._id == yieldID
                          ? "active"
                          : ""
                      }><div className="name_date">
                            {currentPack &&
                              yiledStakeType == 1 ? (
                              <h4>
                                {
                                  currentPack.yiled_1_firstDuration
                                }
                              </h4>
                            ) : yiledStakeType == 3 ? (
                              <h4>
                                {
                                  currentPack.yiled_3_firstDuration
                                }
                              </h4>
                            ) : yiledStakeType == 5 ? (
                              <h4>
                                {
                                  currentPack.yiled_5_firstDuration
                                }
                              </h4>
                            ) : (
                              ""
                            )}
                            <span>Days</span></div></a></li>

                      <li className={
                        yieldDuration ==
                          "stake2" &&
                          currentPack._id == yieldID
                          ? "active"
                          : ""
                      }
                        onClick={() =>
                          chooseDurationYield(
                            "stake2",
                            currentPack
                          )
                        }
                      ><a data-toggle="tab" href="#day0" className={
                        yieldDuration ==
                          "stake2" &&
                          currentPack._id == yieldID
                          ? "active"
                          : ""
                      }><div className="name_date">
                            {currentPack &&
                              yiledStakeType == 1 ? (
                              <h4>
                                {
                                  currentPack.yiled_1_secondDuration
                                }
                              </h4>
                            ) : yiledStakeType == 3 ? (
                              <h4>
                                {
                                  currentPack.yiled_3_secondDuration
                                }
                              </h4>
                            ) : yiledStakeType == 5 ? (
                              <h4>
                                {
                                  currentPack.yiled_5_secondDuration
                                }
                              </h4>
                            ) : (
                              ""
                            )}
                            <span>Days</span></div></a></li>


                      <li className={
                        yieldDuration ==
                          "stake3" &&
                          currentPack._id == yieldID
                          ? "active"
                          : ""
                      }
                        onClick={() =>
                          chooseDurationYield(
                            "stake3",
                            currentPack
                          )
                        }
                      ><a data-toggle="tab" href="#day0" className={
                        yieldDuration ==
                          "stake3" &&
                          currentPack._id == yieldID
                          ? "active"
                          : ""
                      }><div className="name_date">
                            {currentPack &&
                              yiledStakeType == 1 ? (
                              <h4>
                                {
                                  currentPack.yiled_1_thirdDuration
                                }
                              </h4>
                            ) : yiledStakeType == 3 ? (
                              <h4>
                                {
                                  currentPack.yiled_3_thirdDuration
                                }
                              </h4>
                            ) : yiledStakeType == 5 ? (
                              <h4>
                                {
                                  currentPack.yiled_5_thirdDuration
                                }
                              </h4>
                            ) : (
                              ""
                            )}
                            <span>Days</span></div></a></li>

                      <li className={
                        yieldDuration ==
                          "stake4" &&
                          currentPack._id == yieldID
                          ? "active"
                          : ""
                      }
                        onClick={() =>
                          chooseDurationYield(
                            "stake4",
                            currentPack
                          )
                        }
                      ><a data-toggle="tab" href="#day0" className={
                        yieldDuration ==
                          "stake4" &&
                          currentPack._id == yieldID
                          ? "active"
                          : ""
                      }><div className="name_date">
                            {currentPack &&
                              yiledStakeType == 1 ? (
                              <h4>
                                {
                                  currentPack.yiled_1_fourthDuration
                                }
                              </h4>
                            ) : yiledStakeType == 3 ? (
                              <h4>
                                {
                                  currentPack.yiled_3_fourthDuration
                                }
                              </h4>
                            ) : yiledStakeType == 5 ? (
                              <h4>
                                {
                                  currentPack.yiled_5_fourthDuration
                                }
                              </h4>
                            ) : (
                              ""
                            )}
                            <span>Days</span></div></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-body for_pading_">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form_staking">
                    <h4>Summary</h4>
                    <p><span>Stake Amount</span><span>Available Amount {parseFloat(stakeBalance.currencyBalance).toFixed(
                      4
                    )} {currentPack.currencySymbol}</span></p>
                    <div className="input_form">
                      <input type="number" value={YieldValueref.current} placeholder="Enter amount" onChange={yieldCalculate} min="0" />
                      <Button onClick={yieldCalculate_max}>Max</Button>
                    </div>
                    <div>
                      {validation == true ? (
                        <p className="text-danger">
                          {" "}
                          Stake amount is required{" "}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form_staking">
                    <h4>Staking</h4>
                    <p><span>Stake Date</span><span>{fromDates}</span></p>
                    <p><span>Interest End Date</span><span>{yiledToDate}</span></p>
                    <p><span>Stake Type</span> <span> {yiledStakeType}{""} Yield staking</span></p>
                    <p><span>Interest Type</span> <span>{yieldDuration} Days</span></p>
                    <hr></hr>
                    <p>
                      <span>APR</span>
                      <span>{yieldAPY}%</span>
                    </p>
                    <p><span>Estimated Interest</span><span>{parseFloat(YieldEstimation).toFixed(8)} {stakeBalance.currencySymbol}</span></p>
                    {buttonLoader == false ? (
                      <button className="submit" onClick={confirmStackYield}>Confirm</button>
                    ) : (
                      <button>Loading...</button>
                    )}
                  </div>

                </div>
              </div>

            </div>



          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
