import React, {useState, useEffect} from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import {removeAuthToken} from "../core/lib/localStorage";
import {removeAuthorization} from "../core/service/axios";

const pages = ["Products", "Services", "ABoutUs", "ContactUs"];
const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const drawerWidth = 240;
  const zIndex1 = 2000000;
  const [loginCheck, setloginCheck] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");

    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
  }, [0]);

  const logout = async () => {
    await removeAuthorization();
    await removeAuthToken();
    localStorage.clear();
    navigate("/");
    window.location.reload(true);
  };

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        sx={{
          display: {xs: "block", sm: "block"},
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            zIndex: zIndex1,
          },
        }}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          {/* <ListItemButton>
            <ListItemIcon>
              <ListItemText>
                <Link to="/soon" className="link_tage">
                  <i class="ri-line-chart-fill"></i>
                  Futures
                </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton>
            {" "}
            <ListItemIcon>
              <ListItemText>
                <Link to="/soon" className="link_tage">
                  <i class="ri-rocket-2-fill"></i>
                  Launchpad
                </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton> */}
          {loginCheck == true ? (
            <>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/dashboard" className="link_tage">
                      <i class="ri-user-3-fill"></i>
                      Dashboard
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/kyc" className="link_tage">
                      <i class="ri-user-follow-fill"></i>
                      KYC
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/security" className="link_tage">
                      <i class="ri-lock-fill"></i>
                      Security
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>

              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/deposit" className="link_tage">
                      <i class="ri-align-bottom"></i>
                      Deposit
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/withdraw" className="link_tage">
                      <i class="ri-align-top"></i>
                      Withdraw
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>

              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/swap" className="link_tage">
                      <i class="ri-exchange-funds-fill"></i>
                      Swap
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
            </>
          ) : (
            ""
          )}

          <ListItemButton>
            <ListItemIcon>
              <ListItemText>
                <Link to="/markets" className="link_tage">
                  <i class="ri-store-fill"></i>
                  Market
                </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <ListItemText>
                <Link to="/trade/ADVB_USDT" className="link_tage">
                  <i class="ri-line-chart-fill"></i>
                  Spot
                </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton>
          {loginCheck == true ? (
            <ListItemButton>
              {" "}
              <ListItemIcon>
                <ListItemText>
                  <Link to="/p2p" className="link_tage">
                    <i class="ri-p2p-fill"></i>
                    P2P
                  </Link>
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
          ) : (
            <ListItemButton>
              {" "}
              <ListItemIcon>
                <ListItemText>
                  <Link to="/login" className="link_tage">
                    <i class="ri-p2p-fill"></i>
                    P2P
                  </Link>
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
          )}
          {loginCheck == true ? (
            <ListItemButton>
              {" "}
              <ListItemIcon>
                <ListItemText>
                  <Link to="/staking" className="link_tage">
                    <i class="ri-plant-line"></i>
                    Staking
                  </Link>
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
          ) : (
            <ListItemButton>
              {" "}
              <ListItemIcon>
                <ListItemText>
                  <Link to="/login" className="link_tage">
                    <i class="ri-plant-line"></i>
                    Staking
                  </Link>
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
          )}
          <ListItemButton>
            {" "}
            <ListItemIcon>
              <ListItemText>
                <Link to="/refferal" className="link_tage">
                  <i class="ri-user-add-fill"></i>
                  Referral
                </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton>

          {loginCheck == false ? (
            <ListItemButton>
              {" "}
              <ListItemIcon>
                <ListItemText>
                  <Link to="/login" className="link_tage">
                    <i class="ri-login-box-fill"></i>
                    Login
                  </Link>
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
          ) : (
            ""
          )}

          {loginCheck == false ? (
            <ListItemButton>
              {" "}
              <ListItemIcon>
                <ListItemText>
                  <Link to="/register" className="link_tage">
                    <i class="ri-user-shared-fill"></i>
                    Register
                  </Link>
                </ListItemText>
              </ListItemIcon>{" "}
            </ListItemButton>
          ) : (
            ""
          )}

          {loginCheck == true ? (
            <ListItemButton>
              {" "}
              <ListItemIcon>
                <ListItemText>
                  <Link to="" className="link_tage" onClick={logout}>
                    <i class="ri-user-shared-fill"></i>
                    Logout
                  </Link>
                </ListItemText>
              </ListItemIcon>{" "}
            </ListItemButton>
          ) : (
            ""
          )}
        </List>
      </Drawer>
      <IconButton
        sx={{color: "black", marginLeft: "auto"}}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="black" />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
