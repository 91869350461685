import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import Moment from "moment";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import Sidebar from "./Sidebarlink";

function Home() {
  const initialFormValue = {
    AccountHolderName: "",
    AccountNumber: "",
    IFSCCode: "",
    BankName: "",
    BranchName: "",
    BranchAddress: "",
    gpay_number: "",
    paytm_number: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const [
    AccountHolderNameErr,
    SetAccountHolderNameErr,
    AccountHolderNameErrref,
  ] = useState(false);
  const [AccountNumberErr, SetAccountNumberErr, AccountNumberErrref] =
    useState(false);
  const [IFSCCodeErr, SetIFSCCodeErr, IFSCCodeErrref] = useState(false);
  const [BankNameErr, SetBankNameErr, BankNameErrref] = useState(false);
  const [BranchNameErr, SetBranchNameErr, BranchNameErrref] = useState(false);
  const [gpayNumber, setgpayNumber, gpayNumberref] = useState(false);
  const [paytmNumber, setpaytmNumber, paytmNumberref] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [BranchAddressErr, SetBranchAddressErr, BranchAddressErrref] =
    useState(false);
  const [Bankdetails, SetBankdetails] = useState([]);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [loader, setLoader] = useState(false);

  const {
    AccountHolderName,
    AccountNumber,
    IFSCCode,
    BankName,
    BranchName,
    BranchAddress,
    gpay_number,
    paytm_number,
  } = formValue;

  const navigate = useNavigate();

  const handleChange = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    console.log("formData====", formData);
    setFormValue(formData);
  };

  const validate = async (values) => {
    const errors = {};
    if (values.AccountHolderName == "") {
      errors.AccountHolderName = "Account Holder Name is required !";
      SetAccountHolderNameErr(true);
    }
    // else if (
    //     values.AccountHolderName.length < 5 ||
    //     values.AccountHolderName.length > 25
    // ) {
    //     errors.AccountHolderName =
    //         "Account Holder Name must hava an 5 to 25 characters!";
    //     SetAccountHolderNameErr(true);
    // }
    else {
      SetAccountHolderNameErr(false);
    }
    if (values.AccountNumber == "") {
      errors.AccountNumber = "Account Number is required !";
      SetAccountNumberErr(true);
    }
    // else if (
    //     values.AccountNumber.length < 5 ||
    //     values.AccountNumber.length > 25
    // ) {
    //     errors.AccountNumber = "Account Number  must hava an 5 to 25 Digits!";
    //     SetAccountNumberErr(true);
    // }
    else {
      SetAccountNumberErr(false);
    }
    if (values.IFSCCode == "") {
      errors.IFSCCode = "IFSC Code is required !";
      SetIFSCCodeErr(true);
    }
    // else if (values.IFSCCode.length < 5 || values.IFSCCode.length > 25) {
    //     errors.IFSCCode = "IFSC Code must have an 5 to 25 characters!";
    //     SetIFSCCodeErr(true);
    // }
    else {
      SetIFSCCodeErr(false);
    }
    if (values.BankName == "") {
      errors.BankName = "Bank Name is required !";
      SetBankNameErr(true);
    }
    // else if (values.BankName.length < 5 || values.BankName.length > 25) {
    //     errors.BankName = "Bank Name must have an 5 to 25 characters!";
    //     SetBankNameErr(true);
    // }
    else {
      SetBankNameErr(false);
    }
    if (values.BranchName == "") {
      errors.BranchName = "Branch Name is required !";
      SetBranchNameErr(true);
    }
    // else if (values.BranchName.length < 5 || values.BranchName.length > 25) {
    //     errors.BranchName = "Branch Name must have an 5 to 25 !";
    //     SetBranchNameErr(true);
    // }
    else {
      SetBranchNameErr(false);
    }
    if (values.BranchAddress == "") {
      errors.BranchAddress = "Branch Address is required !";
      SetBranchAddressErr(true);
    }
    // else if (
    //     values.BranchAddress.length < 5 ||
    //     values.BranchAddress.length > 150
    // ) {
    //     errors.BranchAddress = "Branch Address must have an 5 to 150 characters!";
    //     SetBranchAddressErr(true);
    // }
    else {
      SetBranchAddressErr(false);
    }
    // if (values.gpay_number == "") {
    //     errors.gpay_number = "Gpay Details is required !";
    //     setgpayNumber(true);
    // }

    // if (values.paytm_number == "") {
    //     errors.paytm_number = "Paytm Details is required !";
    //     setpaytmNumber(true);
    // }

    setvalidationnErr(errors);
    return errors;
  };

  useEffect(() => {
    getbankdetails();
  }, [0]);

  const submitID = async () => {
    try {
      validate(formValue);

      if (
        formValue.AccountHolderName != "" &&
        // formValue.AccountHolderName.length >= 5 &&
        // formValue.AccountHolderName.length <= 25 &&
        formValue.AccountNumber != "" &&
        // formValue.AccountNumber.length >= 5 &&
        // formValue.AccountNumber.length <= 25 &&
        formValue.IFSCCode != "" &&
        // formValue.IFSCCode.length >= 5 &&
        // formValue.IFSCCode.length <= 25 &&
        formValue.BankName != "" &&
        // formValue.BankName.length >= 5 &&
        // formValue.BankName.length <= 25 &&
        formValue.BranchName != "" &&
        // formValue.BranchName.length >= 5 &&
        // formValue.BranchName.length <= 25 &&
        formValue.BranchAddress != ""
        // formValue.BranchAddress.length >= 5 &&
        // formValue.BranchAddress.length <= 150 &&
        // formValue.gpay_number != "" &&
        // formValue.paytm_number != ""
      ) {
        setbuttonLoader(true);

        var data = {
          apiUrl: apiService.Bankdetails,
          payload: formValue,
        };

        var resp = await postMethod(data);
        console.log(resp, "=-=-resp=--=-=");
        if (resp.status) {
          setbuttonLoader(false);
          formValue.AccountHolderName = "";
          formValue.AccountNumber = "";
          formValue.IFSCCode = "";
          formValue.BankName = "";
          formValue.BranchAddress = "";
          formValue.gpay_number = "";
          formValue.paytm_number = "";
          getbankdetails();
          toast.success(resp.Message);
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Please fill all the require  fields");
        console.log("ALL FIELD NEED");
      }
    } catch (error) {}
  };

  const getbankdetails = async () => {
    try {
      setLoader(true);
      var data = {
        apiUrl: apiService.Getbankdetails,
      };
      var resp = await getMethod(data);
      console.log("getbankdetails===", resp);

      if (resp) {
        setLoader(false);
        console.log(resp.obj, "=-=-respobj-=-=");
        console.log(resp, "-=-=-resp=-=-=-resp==-resp");
        SetBankdetails(resp.obj);
      } else {
        console.log(resp, "-=-=-resp=-=-=-resp==-resp");
      }
    } catch (error) {
      console.log(error, "=-=error=-=-=");
    }
  };

  const defaultBank = async (editData) => {
    console.log(editData, "=-=-=-=defaultBank");
    try {
      var data = {
        apiUrl: apiService.defaultBanks,
        payload: editData,
      };
      console.log(data, "=-=datap-0-");
      var resp = await postMethod(data);
      console.log(resp, "=-=resp--=");
      if (resp.status) {
        toast.success(resp.Message);
        getbankdetails();
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {}
  };
  const deletebank = async (deleteData) => {
    alert("Are you sure you want to permanently delete this bank details ?");
    console.log(deleteData, "=-=-=-=deleteData");
    try {
      var data = {
        apiUrl: apiService.deletbank,
        payload: deleteData,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        getbankdetails();
        toast.success(resp.Message);
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {}
  };

  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght">
              {loader == true ? (
                <div class="spinner css-ezm1fk">
                  <div class="css-1qoumk2"></div>
                  <div class="css-1v19680"></div>
                  <div class="css-1v90jie"></div>
                  <div class="css-19p0rvp"></div>
                </div>
              ) : (
                <>
                  <section className="deposut_section">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="headin_deposur_s">
                            <h3>
                              {/* <Link to="/dashboard">
                          <i class="ri-arrow-left-fill"></i>
                        </Link> */}
                              Merchant
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="form-deposit">
                    <div className="container-fluid">
                      <h4 className="heading_class_recent p-3">
                        New Payment Button
                      </h4>
                      <div className="row justify-content-center payment_burron">
                        <div className="col-lg-6">
                          <div className="qr_section_class">
                            <div className="crypto_coin_selec pa-_opo">
                              <label>Title</label>

                              <div className="flex_coin_copy">
                                <input
                                  type="text"
                                  placeholder="Title"
                                  name="AccountHolderName"
                                  value={AccountHolderName}
                                  onChange={handleChange}
                                />
                              </div>
                              <div>
                                {AccountHolderNameErrref.current == true ? (
                                  <p className="text-danger">
                                    {" "}
                                    {validationnErr.AccountHolderName}{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="crypto_coin_selec pa-_opo">
                              <label>Button Text</label>
                              <div className="flex_coin_copy">
                                <input
                                  type="text"
                                  placeholder="Button Text"
                                  name="AccountNumber"
                                  value={AccountNumber}
                                  onChange={handleChange}
                                />
                              </div>
                              <div>
                                {AccountNumberErrref.current == true ? (
                                  <p className="text-danger">
                                    {" "}
                                    {validationnErr.AccountNumber}{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div className="crypto_coin_selec pa-_opo">
                              <label>Logo URL</label>
                              <div className="flex_coin_copy">
                                <input
                                  type="text"
                                  placeholder="Logo URL"
                                  name="IFSCCode"
                                  value={IFSCCode}
                                  onChange={handleChange}
                                />
                              </div>
                              <div>
                                {IFSCCodeErrref.current == true ? (
                                  <p className="text-danger">
                                    {" "}
                                    {validationnErr.IFSCCode}{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          {buttonLoader == false ? (
                            <div class="form_login_input">
                              <Button onClick={submitID}>Submit</Button>
                            </div>
                          ) : (
                            <div class="form_login_input">
                              <Button>Loading...</Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="tavle_transactio">
                    <div className="padding_section">
                      <div className="container-fluid">
                        <h4 className="heading_class_recent p-3">
                          Payment Button
                        </h4>
                        <div className="row ">
                          <div className="col-lg-12">
                            <div className="market_tabel w-100 mt-0 p-3">
                              <div className="market_section">
                                <div className="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th>ID</th>
                                        <th>Title</th>
                                        <th>Name ID</th>
                                        <th>Kind</th>
                                        <th>Prices</th>
                                        <th>Currency (Price)</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Bankdetails && Bankdetails.length > 0 ? (
                                        Bankdetails.map((item, i) => {
                                          return (
                                            <tr>
                                              <td>
                                                <input
                                                  type="radio"
                                                  name="flexRadioDefault"
                                                  id="flexRadioDefault2"
                                                  checked={item.Status == 1}
                                                  onClick={() => {
                                                    defaultBank(item);
                                                  }}
                                                />
                                              </td>
                                              <td>
                                                <p>{item.Account_Number}</p>
                                              </td>
                                              <td>
                                                <p>{item.IFSC_code} </p>
                                              </td>

                                              <td>
                                                <p>{item.Bank_Name}</p>
                                              </td>
                                              <td>
                                                <p>{item.Branch_Name}</p>
                                              </td>
                                              <td>
                                                <p>
                                                  <a
                                                    className="text-red"
                                                    onClick={() => {
                                                      deletebank(item);
                                                    }}
                                                  >
                                                    {" "}
                                                    <i class="bi bi-trash-fill"></i>
                                                  </a>
                                                </p>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td colSpan="7">
                                            <p className="text-center">
                                              No records found
                                            </p>
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </main>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
