import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { setAuthorization } from "../core/service/axios";

function Home() {
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  useEffect(() => {
    // adminLogin()
    var token = window.location.href.split("?")[1];
    var gettoken2 = localStorage.getItem('user_token')
    if(token != null){
      var setToken = localStorage.setItem('user_token', token)
      window.location.href = "/dashboard"
      console.log(gettoken2,"=-=-=-=-=-=-=-=-=-=-already token",token)
    }
    // else{
    //   localStorage.clear()
    //   if(token != undefined){
    //   var setToken = localStorage.setItem('user_token', token)
    //   window.location.href = "/dashboard"
    //   }
    // }
  }, [])

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const initialFormValue = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();

  const [emailValidate, setemailValidate] = useState(false);
  const [passwordValidate, setpasswordValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const { email, password } = formValue;

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is a required field";
      setemailValidate(true);
    }

    if (!values.password) {
      errors.password = "Password is a required field";
      setpasswordValidate(true);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const formSubmit = async () => {
    validate(formValue);
    setbuttonLoader(true);
    if (formValue.email != "" && formValue.password != "") {
      var data = {
        apiUrl: apiService.signin,
        payload: formValue,
      };

      var resp = await postMethod(data);
      localStorage.setItem("socket_token", resp.socketToken);
      if (resp?.tfa === 1) {
        setbuttonLoader(false);
        localStorage.setItem("socket_token", resp.socketToken);
        navigate("/tfalogin", {
          state: {
            socketToken: resp.socketToken,
          },
        });
      } else {
        if (resp.status) {
          setbuttonLoader(false);
          toast.success(resp.Message);
          console.log("resp.token====", resp.token);
          localStorage.setItem("user_token", resp.token);
          localStorage.setItem("tfa_status", resp.tfa);
          setAuthorization(resp.token);
          navigate("/dashboard");
          //window.location.href = "https://latoken.com/exchange/ADVB_USDT";
        } else {
          setbuttonLoader(false);
          toast.error(resp.Message);
        }
      }
    }
  };

  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form">
          <div className="container">
            <div className="row ">
              <div className="col-lg-6">
                <div className="card_login">
                  <h3>Adverb Login</h3>
                  <div>
                    <div class="form_login_input">
                      <label>Email</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                      />

                      <div>
                        {emailValidate == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.email}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="form_login_input">
                      <label>Password</label>

                      <input
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={handleChange}
                      />

                      <div>
                        {passwordValidate == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.password}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="form_login_input">
                      {buttonLoader == false ? (
                        <Button onClick={formSubmit}>
                          Login
                        </Button>
                      ) : (
                        <Button>
                          Loading...
                        </Button>
                      )}
                    </div>
                    <div className="resend_mailcls">
                      <div>
                        <Link to="/forget" className="pass_forgee mt-3 d-block">
                          Forget Password ?
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  src={require("../Image/bitcoin-p2p/login.png")}
                  className="img-fluid clo_login_in"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
