import React, {useEffect} from "react";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import {Button} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
// import Bgvedio from "../Image/globe-fiat-block.mp4";
import BannerUI from "./Banner";
import {toast} from "react-toastify";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";
import Moment from "moment";
import Pagination from "react-js-pagination";
import Countdownchat from "./Countdown";
import Countdown from "react-countdown";

import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
  InstapaperIcon,
  InstapaperShareButton,
} from "react-share";

import { socket } from "../core/context/socket";

function Home() {
  const [loader, setLoader] = useState(false);
  const initialFormValue = {
    message: "",
    file: "",
    type: "",
    orderId: "",
    p2porderId: "",
  };

  const p2pFormValue = {
    qty: "",
    total: "",
  };

  const [p2pData, setp2pData, p2pDataref] = useState("");
  const [orderType, setorderType, orderTyperef] = useState("");
  const [p2pdate, setp2pDate, p2pdateref] = useState("");
  const [profileData, setprofileData, profileDataref] = useState("");
  const [formValue, setFormValue, formValueref] = useState(initialFormValue);
  const [p2pfile, setp2pfile, p2pfileref] = useState("");
  const [chatloading, setchatloading] = useState(false);
  const { message, file, type, orderId, p2porderId } = formValue;
  const [p2pchat, setp2pchat, p2pchatref] = useState([]);
  const [interval, setintervalchat, intervalref] = useState("");
  const [runningTimer, setRunningTimer] = useState(false);
  const [bankData, setbankData, bankDataref] = useState("");
  const [socket_token, set_socket_token, socketref] = useState("");
  const [notifyp2pData, setnotifyp2pData, notifyp2pDataref] = useState("");
  const [p2pformValue, setp2pFormValue, p2pformValueref] =
    useState(p2pFormValue);
  const [notifymessage, setnotifymessage, notifymessageref] = useState("");
  const [confirmp2pcheck, setconfirmp2pcheck, confirmp2pcheckref] = useState([]);
  const { qty, total } = p2pformValue;
  const [p2pbalance, setp2pbalance, p2pbalanceref] = useState("");
  const [disputefile, setdisputefile, disputefileref] = useState("");
  const [disputequery, setdisputequery, disputequeryref] = useState("");
  const [confirmp2porder, setconfirmp2porder, confirmp2porderref] = useState("");
  const [p2pbankcheck, setp2pbankcheck, p2pbankcheckref] = useState(false);
  const [p2pbankData, setp2pbankData, p2pbankDataref] = useState("");
  const [Timer, setTimer, Timerref] = useState("");
  const [Timerstatus, setTimerstatus, Timerstatusref] = useState("deactive");

  const [sellTimer, setsellTimer, sellTimerref] = useState("");
  const [sellTimerstatus, setsellTimerstatus, sellTimerstatusref] = useState("deactive");
  const [payTime, setpayTime, payTimeref] = useState("15");
  const navigate = useNavigate();

  
  useEffect(() => {
    let socket_token = localStorage.getItem("socket_token");
    let socketsplit = socket_token.split("_");
    socket.connect();
    getProfile();
    getp2pOrder();
    //getp2pconfirmOrder();
    //getconfirmOrder();

    socket.off("socketResponse");
    socket.on("socketResponse" + socketsplit[0], function (res) {
      if (res.Reason == "p2pchat") {
        getp2pChat();
      }
      else if (res.Reason == "notify") {

        setnotifymessage(res.Message);
        toast.success(res.Message, {
          toastId: "3"
        });
        getp2pOrder();
        //getp2pconfirmOrder();
        getconfirmOrder();
      }
      else if(res.Reason == "ordercancel")
      {
        setnotifymessage(res.Message);
        toast.success(res.Message, {
          toastId: "3"
        });
        getp2pOrder();
        //getp2pconfirmOrder();
        getconfirmOrder();
      }
    });
  }, [0]);

  useEffect(() => {
    return () => {
      socket.disconnect();
    };
  }, []);
  const closePopup = async () => { };

  const getProfile = async () => {
    try {
      setLoader(true);
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        setprofileData(resp.data);
        if(profileDataref.current != "")
        {
          getp2pOrder();
          getp2pChat();
          setLoader(false);
        }
       
      }
    } catch (error) { }
  };

  const getp2pOrder = async () => {
    var onj = {
      orderId: window.location.href.split("/").pop(),
    };
    var data = {
      apiUrl: apiService.getp2pOrder,
      payload: onj,
    };
    var resp = await postMethod(data);
    console.log(resp.Message, "-=-=-resp=-=-");
    // console.log("profileDataref.current._id====",profileDataref.current._id.toString());
    //  console.log("resp.Message.userId.toString()====",resp.Message.userId.toString());
    if (resp) {
      var data = resp.Message;
      console.log("p2p order data===",data)
      setp2pData(resp.Message);
      setp2pbalance(resp.p2pbalance);
      let paymentTime = (resp.Message.pay_time != null && resp.Message.pay_time != "") ? resp.Message.pay_time : "15";
      setpayTime(paymentTime);
      if (resp.bank) {
        setbankData(resp.bank);
      }

      if (resp.Message.orderType == "buy") {
        if (
          profileDataref.current._id.toString() ==
          resp.Message.userId.toString()
        ) {
          setorderType("Buy");
        } else {
          setorderType("Sell");
        }
      } else {
        if (
          profileDataref.current._id.toString() ==
          resp.Message.userId.toString()
        ) {
          setorderType("Sell");
        } else {
          setorderType("Buy");
        }
      }
      //console.log("orderTyperef====", orderTyperef.current);
      var dates = Moment(resp.Message.createdAt).format("DD.MM.YYYY h:m a");
      setp2pDate(dates);
      getconfirmOrder();

      // var payload = {
      //   orderId: window.location.href.split("/").pop(),
      //   type: resp.Message.orderType
      // };
      // var req_data = {
      //   apiUrl: apiService.getnotifyOrder,
      //   payload: payload,
      // };
      // var notify_resp = await postMethod(req_data);
      // console.log(notify_resp, "-=-=-resp=-=-");
      // setnotifyp2pData(notify_resp.Message);
    }
  };

  const getp2pconfirmOrder = async () => {
    var onj = {
      orderId: window.location.href.split("/").pop(),
    };
    var data = {
      apiUrl: apiService.p2p_confirm_check,
      payload: onj,
    };
    var resp = await postMethod(data);
    if (resp) {
      var data = resp.Message;
      setconfirmp2pcheck(resp.Message);
    }
  };


  const getconfirmOrder = async () => {
    var onj = {
      orderId: window.location.href.split("/").pop(),
    };
    var data = {
      apiUrl: apiService.confirmp2porder,
      payload: onj,
    };
    var resp = await postMethod(data);
    console.log(resp, " confirmp2porder -=-=-resp=-=-");
    if (resp) {
      var data = resp.Message;
      setconfirmp2porder(resp.Message);
      console.log("Date.now()", Date.now());
      console.log("Date.now() + 1212122000", Date.now() + 1212122000);
      if (resp.Message.status == 0) {
        var timer = new Date(resp.Message.datetime).getTime() + (payTimeref.current * 60 * 1000);
        var current_time = new Date().getTime();
        if (timer > current_time) {
          setTimerstatus("active");
          setTimer(timer);
        }
        console.log("timer status====", Timerstatusref.current)
        console.log("timer call====", Timerref.current)
        console.log("timer api====", timer)
        console.log("timer current_time====", current_time)
      }
      else if (resp.Message.status == 1) {
        var timer = new Date(resp.Message.paytime).getTime() + (15 * 60 * 1000);
        var current_time = new Date().getTime();
        if (timer > current_time) {
          setsellTimerstatus("active");
          setsellTimer(timer);
        }
        console.log("sell timer status====", sellTimerstatusref.current)
        console.log("sell timer call====", sellTimerref.current)
        console.log("sell timer api====", timer)
        console.log("sell timer current_time====", current_time)
      }

      setp2pbankData(resp.bank_details);
      setp2pbankcheck(true);
    }
  };

  const handleChange = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    console.log("formData====", formData);
    setFormValue(formData);
  };

  const handleChange_confirm = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    console.log("e.target===", e.target.innerText);
    if (e.target.innerText == "Transferred, Notify Seller") {
      buyer_confirmation("Completed");
    } else if (e.target.innerText == "Confirm Release") {
      seller_confirmation("Completed");
    }
  };

  const handleChange_buycancel = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    console.log("e.target===", e.target.innerText);
    buyer_cancel();
  };

  const handleChange_sellcancel = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    console.log("e.target===", e.target.innerText);
    seller_cancel();
  };
  const imageUpload = (type, val) => {
    console.log("type===", type);
    console.log("val===", val);
    const fileExtension = val.name.split(".").at(-1);
    const fileSize = val.size;
    const fileName = val.name;
    console.log("fileExtension===", fileExtension);
    console.log("fileSize===", fileSize);
    console.log("fileName===", fileName);
    if (
      fileExtension != "png" &&
      fileExtension != "jpg" &&
      fileExtension != "jpeg" &&
      fileExtension != "pdf" &&
      fileExtension != "doc" &&
      fileExtension != "docx"
    ) {
      toast.error(
        "File does not support. You must use .png, .jpg,  .jpeg,  .pdf,  .doc,  .docx "
      );
      return false;
    } else if (fileSize > 1000000) {
      toast.error("Please upload a file smaller than 1 MB");
      return false;
    } else {
      const data = new FormData();
      data.append("file", val);
      data.append("upload_preset", "sztbiwly");
      data.append("cloud_name", "taikonz-com");
      console.log("formdata===", data);
      fetch("  https://api.cloudinary.com/v1_1/taikonz-com/auto/upload", {
        method: "post",
        body: data,
      })
        .then((resp) => resp.json())
        .then((data) => {
          console.log("cloudinary upload===", data);
          if (type == "file") {
            setp2pfile(data.secure_url);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const submitChat = async () => {
    try {
      formValue.file = p2pfileref.current;
      formValue.orderId = window.location.href.split("/").pop();
      formValue.p2porderId = confirmp2porderref.current._id;
      formValue.type =
        profileDataref.current._id.toString() ==
          p2pDataref.current.userId.toString()
          ? "advertiser"
          : "user";

      if (formValue.message != "" || formValue.file != "") {
        setchatloading(true);
        var data = {
          apiUrl: apiService.p2pchat,
          payload: formValue,
        };
        var resp = await postMethod(data);
        if (resp.status) {
          setchatloading(false);
          getp2pChat();
          setRunningTimer(true);
          formValue.message = "";
          setp2pfile("");
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Please enter message or attach file");
      }
    } catch (error) { }
  };

  const getp2pChat = async () => {
    var onj = {
      orderId: window.location.href.split("/").pop(),
    };
    var data = {
      apiUrl: apiService.getp2pchat,
      payload: onj,
    };
    var resp = await postMethod(data);
    console.log(resp.Message, "-=-=-resp=-=-");
    if (resp) {
      var data = resp.Message;
      setp2pchat(data);
    }
  };

  const buyer_confirmation = async (status) => {
    try {
      var obj = {
        orderId: window.location.href.split("/").pop(),
        status: status,
      };
      var data = {
        apiUrl: apiService.buyer_confirm,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getp2pChat();
        getp2pOrder();
        getconfirmOrder();
      } else {
        toast.error(resp.Message);
      }
    } catch (error) { }
  };

  const seller_confirmation = async (status) => {
    try {
      var obj = {
        orderId: window.location.href.split("/").pop(),
        status: status,
      };
      var data = {
        apiUrl: apiService.seller_confirm,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getp2pOrder();
        getconfirmOrder();
        setRunningTimer(false);
        clearInterval(intervalref.current);
      } else {
        toast.error(resp.Message);
      }
    } catch (error) { }
  };

  const buyer_cancel = async (status) => {
    try {
      var obj = {
        orderId: window.location.href.split("/").pop(),
        status: status,
      };
      var data = {
        apiUrl: apiService.buyer_pay_cancel,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getp2pChat();
        getp2pOrder();
        navigate("/p2p");
      } else {
        toast.error(resp.Message);
      }
    } catch (error) { }
  };

  const seller_cancel = async (status) => {
    try {
      var obj = {
        orderId: window.location.href.split("/").pop(),
        status: status,
      };
      var data = {
        apiUrl: apiService.seller_cancel,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getp2pOrder();
      } else {
        toast.error(resp.Message);
      }
    } catch (error) { }
  };

  const copy_to_clipboard = async (type, text) => {
    navigator.clipboard.writeText(text);
    toast.success(type + " copied successfully");
  };

  const confirm_handleChange = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    const { name, value } = e.target;
    let p2pformData = { ...formValue, ...{ [name]: value } };
    setp2pFormValue(p2pformData);
    console.log("p2pformValueref.current.qty===", p2pformValueref.current.qty);
    if (p2pformValueref.current.qty > 0) {
      var order_qty = p2pformValueref.current.qty;
      var min_qty = p2pDataref.current.fromLimit;
      var max_qty = p2pDataref.current.toLimit;
      console.log("min_qty===", min_qty);
      console.log("max_qty===", max_qty);
      console.log("order_qty===", order_qty);
      if (
        order_qty < p2pDataref.current.fromLimit ||
        order_qty > p2pDataref.current.toLimit
      ) {
        toast.error(
          "Please enter quantity between " + min_qty + " and " + max_qty + ""
        );
      } else {
        p2pformValueref.current.total = order_qty * p2pDataref.current.price;
        console.log("p2pformData====", p2pformValueref.current);
      }
    } else {
      toast.error("Please enter valid quantity");
    }
  };




  const dispute_handleChange = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    setdisputequery(e.target.value)
  };

  const disputeUpload = (type, val) => {
    console.log("type===", type);
    console.log("val===", val);
    const fileExtension = val.name.split(".").at(-1);
    const fileSize = val.size;
    const fileName = val.name;
    console.log("fileExtension===", fileExtension);
    console.log("fileSize===", fileSize);
    console.log("fileName===", fileName);
    if (
      fileExtension != "png" &&
      fileExtension != "jpg" &&
      fileExtension != "jpeg" &&
      fileExtension != "pdf" &&
      fileExtension != "doc" &&
      fileExtension != "docx"
    ) {
      toast.error(
        "File does not support. You must use .png, .jpg,  .jpeg,  .pdf,  .doc,  .docx "
      );
      return false;
    } else if (fileSize > 1000000) {
      toast.error("Please upload a file smaller than 1 MB");
      return false;
    } else {
      const data = new FormData();
      data.append("file", val);
      data.append("upload_preset", "sztbiwly");
      data.append("cloud_name", "taikonz-com");
      console.log("formdata===", data);
      fetch("  https://api.cloudinary.com/v1_1/taikonz-com/auto/upload", {
        method: "post",
        body: data,
      })
        .then((resp) => resp.json())
        .then((data) => {
          console.log("cloudinary upload===", data);
          if (type == "file") {
            setdisputefile(data.secure_url);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const dispute_buy = async () => {
    try {
      console.log("confirmp2porderref.current buy====", confirmp2porderref.current)
      var obj = {};
      obj.type = "buy";
      obj.query = disputequeryref.current;
      obj.attachment = disputefileref.current;
      obj.orderId = confirmp2porderref.current.orderId;
      obj.p2p_orderId = window.location.href.split("/").pop();

      if (obj.query != "") {
        var data = {
          apiUrl: apiService.raise_dispute,
          payload: obj,
        };
        var resp = await postMethod(data);
        if (resp.status) {
          toast.success(resp.Message);
          getp2pOrder();
          getp2pconfirmOrder();
          setdisputequery("");
          setdisputefile("");
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Please enter reason for dispute");
      }
    } catch (error) { }
  };


  const dispute_sell = async () => {
    try {
      console.log("confirmp2pcheckref.current sell====", confirmp2porderref.current)
      var obj = {};
      obj.type = "sell";
      obj.query = disputequeryref.current;
      obj.attachment = disputefileref.current;
      obj.orderId = confirmp2porderref.current.orderId;
      obj.p2p_orderId = window.location.href.split("/").pop();

      if (obj.query != "") {
        var data = {
          apiUrl: apiService.raise_dispute,
          payload: obj,
        };
        var resp = await postMethod(data);
        if (resp.status) {
          toast.success(resp.Message);
          getp2pOrder();
          getp2pconfirmOrder();
          setdisputequery("");
          setdisputefile("");
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Please enter reason for dispute");
      }
    } catch (error) { }
  };


  const cancel_confirm_buy = async () => {
    var onj = {
      orderId: window.location.href.split("/").pop(),
    };
    var data = {
      apiUrl: apiService.cancelConfirmBuy,
      payload: onj,
    };
    var resp = await postMethod(data);
    console.log(resp, " confirmp2porder -=-=-resp=-=-");
    if (resp.status) {
      setTimerstatus("deactive");
      setTimer("");
      toast.error(resp.Message);
      navigate("/p2p");
    }
  };

  const cancel_confirmorder_sell = async () => {
    var onj = {
      orderId: window.location.href.split("/").pop(),
    };
    var data = {
      apiUrl: apiService.cancelConfirmSell,
      payload: onj,
    };
    var resp = await postMethod(data);
    console.log(resp, " confirmp2porder -=-=-resp=-=-");
    if (resp.status) {
      toast.error("Timedout order is cancelled");
      setsellTimerstatus("deactive");
      setsellTimer("");
    }
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    
    if (completed) {
      // Render a complete state
      cancel_confirm_buy();
      return (<span></span>)

    }
    else {
      return (
        <div className="countdown">
         <div className="countdown-container hours">
          <span className="countdown-value">{hours}</span>
          <span className="countdown-heading">Hour's</span>
        </div>
        <div className="countdown-container minutes">
          <span className="countdown-value">{minutes}</span>
          <span className="countdown-heading">Min's</span>
        </div>
        <div className="countdown-container seconds">
          <span className="countdown-value">{seconds}</span>
          <span className="countdown-heading">Sec</span>
        </div>
      </div>
      );
    }
  };

  const renderer_sell = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      cancel_confirmorder_sell();
      return (<span></span>)

    }
    else {
      return (
        <div className="countdown">

        <div className="countdown-container hours">
          <span className="countdown-value">{hours}</span>
          <span className="countdown-heading">Hour's</span>
        </div>
        <div className="countdown-container minutes">
          <span className="countdown-value">{minutes}</span>
          <span className="countdown-heading">Min's</span>
        </div>
        <div className="countdown-container seconds">
          <span className="countdown-value">{seconds}</span>
          <span className="countdown-heading">Sec</span>
        </div>
      </div>
      );
    }
  };

  return (
    <div className="header">
      <Header />
      <main>
        {loader == true ? (
          <div class="spinner css-ezm1fk">
            <div class="css-1qoumk2"></div>
            <div class="css-1v19680"></div>
            <div class="css-1v90jie"></div>
            <div class="css-19p0rvp"></div>
          </div>
        ) : (
          <>
            <main className="chat_mainpadi">
              <div className="">
                <div className={orderTyperef.current=="Buy"?"bg_roe":"bg_roe_sell"}> 
                {/* bg_roe_sell */}
                  <div className="container">
                    <div className="row justify-center">
                      <div className="col-lg-12">
                        <div className="chat_details">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="contnte_chat_section">
                                  
                                  {orderTyperef.current == "Buy" ? (
                                    <p>
                                    <span className="green">{orderTyperef.current}</span> {orderTyperef.current} {p2pDataref.current.firstCurrency} From {p2pDataref.current.username} 
                                    </p>
                                  ) : (
                                    <p>
                                      <span className="red">{orderTyperef.current}</span> {orderTyperef.current} {p2pDataref.current.firstCurrency} From {p2pDataref.current.username}
                                      </p>
                                  )}
                                  
                                  {/* <p className="timersection">
                                    This order is created, please wait for
                                    system conformation
                                    <span className="new_count_secti">
                                      <Countdownchat />
                                    </span>
                                  </p> */}
                                </div>
                              </div>
                              {confirmp2porderref.current != "" ? (
                              <div className="col-lg-6">
                                <div className="contnte_chat_section">
                                  <p className="text-right">
                                    Order Number{" "}
                                    <span>{confirmp2porderref.current._id.substring(0,12)}</span>
                                  </p>
                                  <p className="text-right">
                                    Time Created{" "}
                                    <span>{p2pdateref.current}</span>
                                  </p>
                                </div>
                              </div>
                              ) : ("")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container">
                  <div className="row justify-center">
                    <div className="col-lg-12">
                      <div className="chart_section_new">
                        <div className="contanier">
                          <div className="row">
                            <div className="col-lg-7">
                            {profileDataref.current._id != "" && p2pDataref.current != "" &&  confirmp2porderref.current != "" ? (
                              <div className="chat_details_ss">
                                <h4>Confirm Order Info</h4>
                                <div className="account_dteils mb-4">
                                  {/* <div className="section_contant_row">
                                    <label>Quantity</label>
                                    <p>
                                    {p2pDataref.current.firstCurrency}{" "}
                                      <span className="text-green">
                                      {p2pDataref.current.filledAmount}
                                      </span>
                                    </p>
                                  </div> */}
                                  <div className="section_contant_row">
                                    <label>Price</label>
                                    <p>{p2pDataref.current.price}{" "}
                                      {p2pDataref.current.secondCurrnecy}</p>
                                  </div>
                                  {/* <div className="section_contant_row">
                                    <label>Quantity</label>
                                    <p>10.00 USTD</p>
                                  </div> */}
                                  {profileDataref.current._id.toString() ==
                                        p2pDataref.current.userId.toString() && orderTyperef.current == "Sell" && confirmp2porderref.current != "" ? (
                                  <>
                                  <div className="section_contant_row">
                                    <label>Need to release</label>
                                    <p>{confirmp2porderref.current.askAmount}{" "}{p2pDataref.current.firstCurrency}</p>
                                  </div>
                                  <div className="section_contant_row">
                                  <label>You will Get</label>
                                    <p>{parseFloat(confirmp2porderref.current.askAmount * p2pDataref.current.price).toFixed(2)}{" "}
                                              {p2pDataref.current.secondCurrnecy}</p>
                                  </div>
                                  </>
                                  ) : ("")}

                             {profileDataref.current._id.toString() != p2pDataref.current.userId.toString() && orderTyperef.current == "Sell" && confirmp2porderref.current != "" ? (
                                  <>
                                  <div className="section_contant_row">
                                    <label>Need to release</label>
                                    <p>{confirmp2porderref.current.askAmount}{" "}{p2pDataref.current.firstCurrency}</p>
                                  </div>
                                  <div className="section_contant_row">
                                  <label>You will Get</label>
                                    <p>{parseFloat(confirmp2porderref.current.askAmount * p2pDataref.current.price).toFixed(2)}{" "}
                                              {p2pDataref.current.secondCurrnecy}</p>
                                  </div>
                                  </>
                                  ) : ("")}
                                
                                {orderTyperef.current == "Buy" && confirmp2porderref.current != "" ? (
                                <>
                                 <div className="section_contant_row">
                                    <label>Need to Pay</label>
                                    <p>{parseFloat(confirmp2porderref.current.askAmount * p2pDataref.current.price).toFixed(2)}{" "}
                                              {p2pDataref.current.secondCurrnecy}</p>
                                  </div>
                                  <div className="section_contant_row">
                                  <label>You will Get</label>
                                    <p> {confirmp2porderref.current.askAmount}{" "}{p2pDataref.current.firstCurrency}</p>
                                  </div>
                                </>
                                ) : ("")}
                                </div>
                                {orderTyperef.current == "Buy" && confirmp2porderref.current != "" ? (
                                <>
                                <h4 className="mb-4">
                                  Transfer the funds to the sellers’s Account
                                  provided below
                                </h4>
                                {confirmp2porderref.current && bankDataref.current == "" ? (
                                            ""
                                 ) : (
                                <>
                                 {bankDataref.current ? (
                                  <>
                                  <p className="lable_section">Bank Transfer:</p>
                                    <div className="account_dteils mb-4">
                                    <div className="section_contant_row">
                                      <label>Account Number</label>
                                      <p>{bankDataref.current.Account_Number}</p>
                                    </div>
                                    <div className="section_contant_row">
                                      <label>Account Holder</label>
                                      <p>{
                                          bankDataref.current
                                            .Accout_HolderName
                                        }</p>
                                    </div>
                                    <div className="section_contant_row">
                                      <label>Bank Name</label>
                                      <p>{bankDataref.current.Bank_Name}</p>
                                    </div>
                                    <div className="section_contant_row">
                                      <label>Branch Name</label>
                                      <p>{bankDataref.current.Branch_Name}</p>
                                    </div>
                                    <div className="section_contant_row">
                                      <label>IFSC Code</label>
                                      <p>{bankDataref.current.IFSC_code}</p>
                                    </div>
                                  </div>
                                  </>
                                ) : ("")}
                                {bankDataref.current && bankDataref.current.Gpay_Number != undefined && bankDataref.current.Gpay_Number != "" ? (
                                <>
                                <p className="lable_section">UPI ID:</p>
                                <div className="account_dteils mb-4">
                                  <div className="section_contant_row">
                                    <label>UPI Id</label>
                                    <p>{bankDataref.current.Gpay_Number}</p>
                                  </div>
                                </div>
                                </>
                                ) : ("")}
                                {bankDataref.current && bankDataref.current.Paytm_Number != undefined && bankDataref.current.Paytm_Number != "" ? (
                                <>
                                <p className="lable_section">Paytm:</p>
                                <div className="account_dteils mb-4">
                                  <div className="section_contant_row">
                                    <label>Paytm</label>
                                    <p>{bankDataref.current.Paytm_Number}</p>
                                  </div>
                                </div>
                                </>
                                ) : ("")}

                                {/* <p className="lable_section">Paytm:</p>
                                <div className="account_dteils mb-4">
                                  <div className="section_contant_row">
                                    <label>Name</label>
                                    <p>junawasarkar</p>
                                  </div>
                                  <div className="section_contant_row">
                                    <label>Account</label>
                                    <p>8909890989</p>
                                  </div>
                                </div>
                                <p className="lable_section">Gpay :</p>
                                <div className="account_dteils mb-4">
                                  <div className="section_contant_row">
                                    <label>Name</label>
                                    <p>junawasarkar</p>
                                  </div>
                                  <div className="section_contant_row">
                                    <label>Account</label>
                                    <p>8909890989</p>
                                  </div>
                                </div> */}
                                </>
                                 )}
                                </>
                                ) : ("")}

                                {orderTyperef.current == "Buy" &&
                                  profileDataref.current._id.toString() ==
                                  p2pDataref.current.userId.toString() &&
                                  confirmp2porderref.current.status == 0 &&
                                  Timerstatusref.current == "active" ? (
                                  <p className="timersection">
                                    Payment to be made within {(payTimeref.current < 60) ? payTimeref.current + " minutes" : (payTimeref.current / 60 == 1) ? payTimeref.current / 60 + " hour" : payTimeref.current / 60 + " hours"} {" "}
                                    <span className="new_count_secti">
                                    <Countdown
                                          date={Timerref.current}
                                          renderer={renderer}
                                        />
                                    </span>
                                  </p>
                                ) : (
                                  ""
                                )}
                                
                                {orderTyperef.current == "Buy" &&
                                  profileDataref.current._id.toString() ==
                                  p2pDataref.current.userId.toString() &&
                                  confirmp2porderref.current.status == 0 ? (
                                  <>
                                   <h4 className="mb-4">
                                    After transferring the funds, Click on the
                                    “Transferred, notify seller ” button
                                  </h4>
                                  <div className="action_btn">
                                  <Button className="width_full" onClick={handleChange_confirm}>
                                    Transferred, notify seller
                                  </Button>
                                  <Button className="traspanree" onClick={handleChange_buycancel}>
                                    Cancel order
                                  </Button>
                                  </div>
                                  </>

                                ) : (
                                  ""
                                )}

                               {orderTyperef.current == "Buy" &&
                                  profileDataref.current._id.toString() !=
                                  p2pDataref.current.userId.toString() &&
                                  confirmp2porderref.current.status == 0 &&
                                  Timerstatusref.current == "active" ? (
                                    <p className="timersection">
                                    Payment to be made within {(payTimeref.current < 60) ? payTimeref.current + " minutes" : (payTimeref.current / 60 == 1) ? payTimeref.current / 60 + " hour" : payTimeref.current / 60 + " hours"} {" "}
                                    <span className="new_count_secti">
                                    <Countdown
                                          date={Timerref.current}
                                          renderer={renderer}
                                        />
                                    </span>
                                  </p>
                                ) : ("")}

                                {orderTyperef.current == "Buy" &&
                                  profileDataref.current._id.toString() !=
                                  p2pDataref.current.userId.toString() &&
                                  confirmp2porderref.current.status == 0 ? (
                                    <>
                                   <h4 className="mb-4">
                                    After transferring the funds, Click on the
                                    “Transferred, notify seller ” button
                                  </h4>
                                  <div className="action_btn">
                                  <Button className="width_full" onClick={handleChange_confirm}>
                                    Transferred, notify seller
                                  </Button>
                                  <Button className="traspanree" onClick={handleChange_buycancel}>
                                    Cancel order
                                  </Button>
                                  </div>
                                  </>

                                ) : (
                                  ""
                                )}

                               {orderTyperef.current == "Buy" &&
                                  profileDataref.current._id.toString() !=
                                  p2pDataref.current.userId.toString() &&
                                  confirmp2porderref.current.status == 1 ? (
                                    <>
                                    <h4 className="mb-4">
                                     Raise a dispute
                                   </h4>
                                   <div className="action_btn">
                                   <Button className="width_full" data-toggle="modal"
                                      data-target="#raise_dispute_buy">
                                     Raise Dispute
                                   </Button>
                                   </div>
                                   </>
                                ) : (
                                  ""
                                )}


                                {orderTyperef.current == "Buy" &&
                                  profileDataref.current._id.toString() ==
                                  p2pDataref.current.userId.toString() &&
                                  confirmp2porderref.current.status == 1 ? (
                                  <>
                                   <h4 className="mb-4">
                                     Raise a dispute
                                   </h4>
                                   <div className="action_btn">
                                   <Button className="width_full" data-toggle="modal"
                                      data-target="#raise_dispute_buy">
                                     Raise Dispute
                                   </Button>
                                   </div>
                                  </>
                                ) : (
                                  ""
                                )}

                                {orderTyperef.current == "Sell" &&
                                  profileDataref.current._id.toString() ==
                                  p2pDataref.current.userId.toString() &&
                                  confirmp2porderref.current.status == 1 &&
                                  sellTimerstatusref.current == "active" ? (
                                  <p className="timersection">
                                    Buyer paid the amount, Release the crypto within 15 minutes{" "}
                                    <span className="new_count_secti">
                                    <Countdown
                                          date={sellTimerref.current}
                                          renderer={renderer_sell}
                                        />
                                    </span>
                                  </p>
                                ) : (
                                  ""
                                )}

                                {orderTyperef.current == "Sell" &&
                                  profileDataref.current._id.toString() ==
                                  p2pDataref.current.userId.toString() &&
                                  confirmp2porderref.current.status == 1 ? (
                                    <>
                                    <h4 className="mb-4"></h4>
                                    <div className="action_btn">
                                    <Button className="width_full" onClick={handleChange_confirm}>
                                    Confirm Release
                                    </Button>
                                    <Button className="traspanree"  data-toggle="modal"
                                        data-target="#raise_dispute_sell">
                                    Raise Dispute
                                    </Button>
                                    </div>
                                    </>
                                ) : (
                                  ""
                                )}

                                {orderTyperef.current == "Sell" &&
                                  profileDataref.current._id.toString() !=
                                  p2pDataref.current.userId.toString() &&
                                  confirmp2porderref.current.status == 1 &&
                                  sellTimerstatusref.current == "active" ? (
                                    <p className="timersection">
                                    Buyer paid the amount, Release the crypto within 15 minutes{" "}
                                    <span className="new_count_secti">
                                    <Countdown
                                          date={sellTimerref.current}
                                          renderer={renderer_sell}
                                        />
                                    </span>
                                  </p>
                                ) : (
                                  ""
                                )}

                                {orderTyperef.current == "Sell" &&
                                  profileDataref.current._id.toString() !=
                                  p2pDataref.current.userId.toString() &&
                                  confirmp2porderref.current.status == 1 ? (
                                    <>
                                    <h4 className="mb-4"></h4>
                                    <div className="action_btn">
                                    <Button className="width_full" onClick={handleChange_confirm}>
                                    Confirm Release
                                    </Button>
                                    <Button className="traspanree"  data-toggle="modal"
                                        data-target="#raise_dispute_sell">
                                    Raise Dispute
                                    </Button>
                                    </div>
                                    </>
                                ) : (
                                  ""
                                )}


                                {/* <p className="timersection">
                                    This order is created, please wait for
                                    system conformation
                                    <span className="new_count_secti">
                                      <Countdownchat />
                                    </span>
                                  </p> */}


                                {/* <h4 className="mb-4">
                                  After transferring the funds, Click on the
                                  “Transferred, notify seller ” button
                                </h4>
                                <div className="action_btn">
                                  <Button className="width_full">
                                    Transferred, notify seller
                                  </Button>
                                  <Button className="traspanree">
                                    Cancel order
                                  </Button>
                                </div> */}
                              </div>
                            ) : ("")}
                            </div>
                            <div className="col-lg-5">
                              <div className="chat_screen_">
                                {/* <div className="sender_setaoils">
                                  <h2 className="name_sendaer">
                                    <span className="first_letter">j </span>
                                    junawasarkar
                                  </h2>
                                  <div className="account_dteils withths_ss">
                                    <div className="section_contant_row">
                                      <label>30d Trades</label>
                                      <p>760</p>
                                    </div>
                                    <div className="section_contant_row">
                                      <label>30d Completion rate</label>
                                      <p>99.44%</p>
                                    </div>
                                  </div>
                                </div> 
                                <div className="chat_section">
                                  <div className="chat_content">
                                    <div className="chat_send_content">
                                      <p>
                                        {" "}
                                        After transferring the funds, Click on
                                        the “Transferred, notify seller ” button
                                      </p>
                                    </div>
                                    <div className="chat_recive_content">
                                      <p>
                                        {" "}
                                        After transferring the funds, Click on
                                        the “Transferred, notify seller ” button
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                */}
                              
                                <div className="chat_section">
                                  <div className="chat_content">
                                  {p2pchatref.current && p2pchatref.current.length > 0 ? (
                                  p2pchatref.current.map((chat, i) => {
                                  return chat.type == "advertiser" ? (
                                      chat.advertiserId.toString() == profileDataref.current._id.toString() && chat.default == 0 ? (
                                      <>
                                       <div className="chat_send_content">
                                        <span>
                                        {Moment(chat.createdAt).fromNow()}
                                      </span>
                                      {chat.adv_msg != "" &&
                                        chat.adv_msg != undefined ? (
                                        <p>{chat.adv_msg}</p>
                                      ) : (
                                        chat.user_msg != "" &&
                                          chat.user_msg != undefined ? (
                                          <p>{chat.user_msg}</p>
                                        )
                                          : ("")

                                      )}
                                      {chat.adv_file != "" &&
                                        chat.adv_file != undefined ? (
                                        <img
                                          src={chat.adv_file}
                                          className=""
                                        />
                                      ) : (
                                        chat.user_file != "" &&
                                          chat.user_file != undefined ? (
                                          <img
                                            src={chat.user_file}
                                            className=""
                                          />
                                        ) : (
                                          ""
                                        )
                                      )}
                                      </div>
                                      </>
                                    ) : (
                                      chat.advertiserId.toString() != profileDataref.current._id.toString() ? (
                                        <div className="chat_recive_content">
                                          <div className="chat_rix">
                                          <span>
                                              {Moment(chat.createdAt).fromNow()}
                                            </span>
                                            {chat.user_msg != "" &&
                                              chat.user_msg != undefined ? (
                                              <p>{chat.user_msg}</p>
                                            ) : (
                                              chat.adv_msg != "" &&
                                                chat.adv_msg != undefined ? (
                                                <p>{chat.adv_msg}</p>
                                              ) : ("")
                                            )}
                                            {chat.user_file != "" &&
                                              chat.user_file != undefined ? (
                                              <img
                                                src={chat.user_file}
                                                className=""
                                              />
                                            ) : (
                                              chat.adv_file != "" &&
                                                chat.adv_file != undefined ? (
                                                <img
                                                  src={chat.adv_file}
                                                  className=""
                                                />
                                              ) : ("")
                                            )}
                                          </div>
                                          </div>
                                     ) : ("")
                                     )

                                   ) : (
                                     chat.userId.toString() == profileDataref.current._id.toString() && chat.default == 0 ? (
                                      <div className="chat_send_content">
                                      <div className="chat_rix">
                                      <span>
                                      {Moment(chat.createdAt).fromNow()}
                                    </span>
                                    {chat.user_msg != "" &&
                                        chat.user_msg != undefined ? (
                                        <p>{chat.user_msg}</p>
                                      ) : (
                                        chat.adv_msg != "" &&
                                          chat.adv_msg != undefined ? (
                                          <p>{chat.adv_msg}</p>
                                        ) : ("")
                                      )}
                                      {chat.user_file != "" &&
                                        chat.user_file != undefined ? (
                                        <img
                                          src={chat.user_file}
                                          className=""
                                        />
                                      ) : (
                                        chat.adv_file != "" &&
                                          chat.adv_file != undefined ? (
                                          <img
                                            src={chat.adv_file}
                                            className=""
                                          />
                                        ) : ("")
                                      )}
                                      </div>
                                    </div>
                                    ) : (
                                      chat.userId.toString() != profileDataref.current._id.toString()  ? (
                                        <div className="chat_recive_content">
                                          <span>
                                              {Moment(chat.createdAt).fromNow()}
                                            </span>
                                            {chat.user_msg != "" &&
                                              chat.user_msg != undefined ? (
                                              <p>{chat.user_msg}</p>
                                            ) : (
                                              chat.adv_msg != "" &&
                                                chat.adv_msg != undefined ? (
                                                <p>{chat.adv_msg}</p>
                                              ) : ("")
                                            )}
                                            {chat.user_file != "" &&
                                              chat.user_file != undefined ? (
                                              <img
                                                src={chat.user_file}
                                                className=""
                                              />
                                            ) : (
                                              chat.adv_file != "" &&
                                                chat.adv_file != undefined ? (
                                                <img
                                                  src={chat.adv_file}
                                                  className=""
                                                />
                                              ) : ("")
                                            )}
                                          </div>
                                           ) : ("")
                                           )
                                         );
                                       }) ) : ("")}
                                    
                                  </div>
                                </div>
                               
                                {confirmp2porderref.current.status != 2 ? (
                                <div className="inbt_class_send">
                                  <input
                                    type="text"
                                    placeholder="Send Message"
                                    name="message"
                                    value={message}
                                    onChange={handleChange}
                                  />
                                  {p2pfileref.current != "" ? (
                                    <img src={p2pfileref.current} height="30" width="30" />
                                  ) : ("")}
                                  <div className="send_button">
                                    <div className="flie_attac">
                                      <input type="file" name="file"
                                        onChange={(e) =>
                                          imageUpload("file", e.target.files[0])
                                        }/>
                                      <span>
                                        {" "}
                                        <i class="ri-attachment-line"></i>
                                      </span>
                                    </div>
                                    <a onClick={submitChat}>
                                      <i class="ri-send-plane-2-fill"></i>
                                    </a>
                                  </div>
                                </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>

        <div id="success_msg" class="modal launchpad_doce fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-body model_confirms">
              <h1> {notifymessageref.current}</h1>
            </div>
            <div class="modal-footer">
              <Button
                type="button"
                class="btn btn-default"
                data-dismiss="modal"
                onClick={closePopup}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>


      <div id="raise_dispute_buy" class="modal launchpad_doce fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-body model_confirms">
              <h1> Raise Dispute </h1>
              <form className="launch_card new_table tranbg">
                <div className="input_section">
                  <p>
                    <span>Reason for dispute</span>
                  </p>
                  <div className="input_select_s newbtind">
                    <input
                      type="text"
                      name="query"
                      value={disputequeryref.current}
                      onChange={dispute_handleChange}
                    />
                  </div>
                </div>
                <div className="input_section">
                  <p>
                    <span>Attachment</span>
                  </p>
                  <div className="input_select_s">
                  <div class="custom-file">
    <input type="file" class="custom-file-input" id="customFile" onChange={(e) => disputeUpload("file", e.target.files[0])}  />
    <label class="custom-file-label" for="customFile">Choose file</label>
  </div>

                  </div>
                </div>
                <div className="submiot">
                  <Button
                    onClick={dispute_buy}
                    data-dismiss="modal"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


      <div id="raise_dispute_sell" class="modal launchpad_doce fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-body model_confirms">
              <h1> Raise Dispute </h1>
              <form className="launch_card new_table tranbg">
                <div className="input_section">
                  <p>
                    <span>Reason for dispute</span>
                  </p>
                  <div className="input_select_s newbtind">
                    <input
                      type="text"
                      name="query"
                      value={disputequeryref.current}
                      onChange={dispute_handleChange}
                    />
                  </div>
                </div>
                <div className="input_section">
                  <p>
                    <span>Attachment</span>
                  </p>
                  <div className="input_select_s">
                  <div class="custom-file">
    <input type="file" class="custom-file-input" id="customFile" onChange={(e) => disputeUpload("file", e.target.files[0])}  />
    <label class="custom-file-label" for="customFile">Choose file</label>
  </div>
                  </div>
                </div>
                <div className="submiot">
                  <Button
                    onClick={dispute_sell}
                    data-dismiss="modal"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
          </>
        )}
      </main>
      <Footer />
    </div>
  );
}

export default Home;
