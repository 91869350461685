import React, {useState, useEffect} from "react";
import Header from "./Header";
import {Button} from "@mui/material";
import Footer from "./Footer";

function Home() {
  return (
    <div className="header tradepage">
      <Header />
      <main className="trader_page_pading">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-lg-9 p-0">
              <div className="row">
                <div className="col-lg-12 p-0">
                  <div className="trader_header">
                    <div className="pair_section">
                      <h3>BTC/USDT</h3>
                    </div>
                    <div className="pair_section_chind">
                      <p className="text-greene">28,297.11</p>
                      <span>$28,293.85</span>
                    </div>
                    <div className="pair_section_chind">
                      <p>24h Change</p>
                      <span>-63.29 -0.22%</span>
                    </div>
                    <div className="pair_section_chind">
                      <p>24h High</p>
                      <span>28,577.00</span>
                    </div>
                    <div className="pair_section_chind">
                      <p>24h Low</p>
                      <span>28,135.80</span>
                    </div>
                    <div className="pair_section_chind">
                      <p>24h Volume(BTC)</p>
                      <span>25,591.51</span>
                    </div>
                    <div className="pair_section_chind">
                      <p>24h Volume(USDT)</p>
                      <span>725,920,243.35</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 p-0">
                  <article className="ordre-book">
                    <section class="orderbook-header">
                      <table width="100%">
                        <tr>
                          <th class="price">Price</th>
                          <th>Ammount</th>
                          <th>Total </th>
                        </tr>
                      </table>
                    </section>
                    <section class="side" id="asks">
                      <table width="100%">
                        <tr>
                          <td class="price sell priceclick">1000</td>
                          <td>0.0000</td>
                          <td>100</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.000</td>
                          <td>805</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.000</td>
                          <td>1350</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.000</td>
                          <td>600</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.000</td>
                          <td>194</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>206</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.000</td>
                          <td>5.200</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.00</td>
                          <td>12.500</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.000</td>
                          <td>175.00</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>33.320</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>1.940</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>96.89</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>7.840</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>7.048</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>64.00</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>1.539</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.00</td>
                          <td>33.416</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>324.50</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.008</td>
                          <td>0.259</td>
                        </tr>
                        <tr>
                          <td class="price sell priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>127.52</td>
                        </tr>
                      </table>
                    </section>
                    <section class="divider">
                      <div class="current-price">1000.00</div>
                    </section>
                    <section class="side bids">
                      <table width="100%">
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>19.90</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>3.80</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>0.99</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>140.00</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>89.760</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>263.600</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>6581</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>5.476</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>4.810</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>34.20</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.043</td>
                          <td>58.00</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>5.116</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.008</td>
                          <td>113.200</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.001</td>
                          <td>0.48</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.0019</td>
                          <td>0.500</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.033</td>
                          <td>2.6219</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.006</td>
                          <td>0.0198</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.00</td>
                          <td>4.76270</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.002</td>
                          <td>99.80</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.002</td>
                          <td>5.000</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.002</td>
                          <td>311.0</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.002</td>
                          <td>44.00</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.002</td>
                          <td>43.100</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.0024</td>
                          <td>41.000</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.002</td>
                          <td>40.9</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.0050</td>
                          <td>5.000</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.0100</td>
                          <td>100.00</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.0111</td>
                          <td>0.90</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.019</td>
                          <td>4.9</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.020</td>
                          <td>15.00</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.0500</td>
                          <td>6.00</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0.2000</td>
                          <td>0.20</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>0000</td>
                          <td>5.0</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>1000</td>
                          <td>1089</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>2000</td>
                          <td>1.00</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>4000</td>
                          <td>2.30</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>5000</td>
                          <td>2.00</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>1900</td>
                          <td>0.0644</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>200</td>
                          <td>1.000</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>500</td>
                          <td>1.00000</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>10000</td>
                          <td>0.00</td>
                        </tr>
                        <tr>
                          <td class="price buy priceclick">1000.00</td>
                          <td>1000</td>
                          <td>0.10</td>
                        </tr>
                      </table>
                    </section>
                  </article>
                </div>
                <div className="col-lg-9 p-0">
                  <div className="chart">
                    <img
                      src={require("../Image/chartss.png")}
                      className="img-fluid"
                    />
                  </div>
                  <div className="order_tabs">
                    <ul class="nav nav-tabs">
                      <li class="active">
                        <a data-toggle="tab" href="#home" className="active">
                          Limit
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#menu1">
                          Market
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#menu2">
                          Stop-limit
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content trade">
                      <div id="home" class="tab-pane fade in active show">
                        <div className="form_marker">
                          <div className="row">
                            <div className="col-lg-6">
                              <form>
                                <p className="avail_balace">
                                  Avbl -<span>0.009 USDT</span>
                                </p>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Price</div>
                                    </div>
                                    <input
                                      type="email"
                                      class="form-control"
                                      id="email"
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">USDT</div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Amount</div>
                                    </div>
                                    <input
                                      type="email"
                                      class="form-control"
                                      id="email"
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">BTC</div>
                                    </div>
                                  </div>
                                </div>

                                <button
                                  type="submit"
                                  class="btn btn-default button_trade buy"
                                >
                                  Buy
                                </button>
                              </form>
                            </div>
                            <div className="col-lg-6">
                              <form>
                                <p className="avail_balace">
                                  Avbl -<span>0.009 USDT</span>
                                </p>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Price</div>
                                    </div>
                                    <input
                                      type="email"
                                      class="form-control"
                                      id="email"
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">USDT</div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Amount</div>
                                    </div>
                                    <input
                                      type="email"
                                      class="form-control"
                                      id="email"
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">BTC</div>
                                    </div>
                                  </div>
                                </div>

                                <button
                                  type="submit"
                                  class="btn btn-default button_trade sell"
                                >
                                  Sell
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="menu1" class="tab-pane fade">
                        <h3>Menu 1</h3>
                        <p>Some content in menu 1.</p>
                      </div>
                      <div id="menu2" class="tab-pane fade">
                        <h3>Menu 2</h3>
                        <p>Some content in menu 2.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 p-0">
              <div className="pair_selec_tab">
                <div className="search_pair">
                  <div className="input_search">
                    <i class="bi bi-search"></i>
                    <input type="text" placeholder="search" />
                  </div>
                </div>
                <ul class="nav nav-tabs">
                  <li class="active">
                    <a data-toggle="tab" href="#BTC" className="active">
                      BTC
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#USTD">
                      USDT
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#ETH">
                      ETH
                    </a>
                  </li>
                </ul>
                <div class="tab-content ">
                  <div id="BTC" class="tab-pane fade in active show">
                    <article className="ordre-book">
                      <section class="orderbook-header">
                        <table width="100%">
                          <tr>
                            <th class="price">Pair</th>
                            <th>Price</th>
                            <th>Change </th>
                          </tr>
                        </table>
                      </section>
                      <section class="side" id="asks">
                        <table width="100%">
                          <tr>
                            <td class="price  priceclick">
                              <span>
                                BTC/<small>USDT</small>
                              </span>
                            </td>
                            <td className="pair_price_witdh">0.0000</td>
                            <td className="pair_price_witdh text-red">0.01%</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick">
                              <span>
                                BTC/<small>USDT</small>
                              </span>
                            </td>
                            <td className="pair_price_witdh">0.0000</td>
                            <td className="pair_price_witdh text-red">0.01%</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick">
                              <span>
                                BTC/<small>USDT</small>
                              </span>
                            </td>
                            <td className="pair_price_witdh">0.0000</td>
                            <td className="pair_price_witdh text-red">0.01%</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick">
                              <span>
                                BTC/<small>USDT</small>
                              </span>
                            </td>
                            <td className="pair_price_witdh">0.0000</td>
                            <td className="pair_price_witdh text-red">0.01%</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick">
                              <span>
                                BTC/<small>USDT</small>
                              </span>
                            </td>
                            <td className="pair_price_witdh">0.0000</td>
                            <td className="pair_price_witdh text-red">0.01%</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick">
                              <span>
                                BTC/<small>USDT</small>
                              </span>
                            </td>
                            <td className="pair_price_witdh">0.0000</td>
                            <td className="pair_price_witdh text-red">0.01%</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick">
                              <span>
                                BTC/<small>USDT</small>
                              </span>
                            </td>
                            <td className="pair_price_witdh">0.0000</td>
                            <td className="pair_price_witdh text-red">0.01%</td>
                          </tr>{" "}
                          <tr>
                            <td class="price  priceclick">
                              <span>
                                BTC/<small>USDT</small>
                              </span>
                            </td>
                            <td className="pair_price_witdh">0.0000</td>
                            <td className="pair_price_witdh text-red">0.01%</td>
                          </tr>{" "}
                          <tr>
                            <td class="price  priceclick">
                              <span>
                                BTC/<small>USDT</small>
                              </span>
                            </td>
                            <td className="pair_price_witdh">0.0000</td>
                            <td className="pair_price_witdh text-red">0.01%</td>
                          </tr>{" "}
                          <tr>
                            <td class="price  priceclick">
                              <span>
                                BTC/<small>USDT</small>
                              </span>
                            </td>
                            <td className="pair_price_witdh">0.0000</td>
                            <td className="pair_price_witdh text-red">0.01%</td>
                          </tr>{" "}
                          <tr>
                            <td class="price  priceclick">
                              <span>
                                BTC/<small>USDT</small>
                              </span>
                            </td>
                            <td className="pair_price_witdh">0.0000</td>
                            <td className="pair_price_witdh text-red">0.01%</td>
                          </tr>{" "}
                          <tr>
                            <td class="price  priceclick">
                              <span>
                                BTC/<small>USDT</small>
                              </span>
                            </td>
                            <td className="pair_price_witdh">0.0000</td>
                            <td className="pair_price_witdh text-red">0.01%</td>
                          </tr>{" "}
                          <tr>
                            <td class="price  priceclick">
                              <span>
                                BTC/<small>USDT</small>
                              </span>
                            </td>
                            <td className="pair_price_witdh">0.0000</td>
                            <td className="pair_price_witdh text-red">0.01%</td>
                          </tr>{" "}
                          <tr>
                            <td class="price  priceclick">
                              <span>
                                BTC/<small>USDT</small>
                              </span>
                            </td>
                            <td className="pair_price_witdh">0.0000</td>
                            <td className="pair_price_witdh text-red">0.01%</td>
                          </tr>{" "}
                          <tr>
                            <td class="price  priceclick">
                              <span>
                                BTC/<small>USDT</small>
                              </span>
                            </td>
                            <td className="pair_price_witdh">0.0000</td>
                            <td className="pair_price_witdh text-red">0.01%</td>
                          </tr>{" "}
                          <tr>
                            <td class="price  priceclick">
                              <span>
                                BTC/<small>USDT</small>
                              </span>
                            </td>
                            <td className="pair_price_witdh">0.0000</td>
                            <td className="pair_price_witdh text-red">0.01%</td>
                          </tr>{" "}
                          <tr>
                            <td class="price  priceclick">
                              <span>
                                BTC/<small>USDT</small>
                              </span>
                            </td>
                            <td className="pair_price_witdh">0.0000</td>
                            <td className="pair_price_witdh text-red">0.01%</td>
                          </tr>
                        </table>
                      </section>
                    </article>
                  </div>
                </div>
              </div>
              <div className="pair_selec_tab bordee_top">
                <ul class="nav nav-tabs pdint_bouut">
                  <li class="active">
                    <a
                      data-toggle="tab"
                      href="#Market_Trade"
                      className="active"
                    >
                      Market Trade
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#My_Trade">
                      My Trade
                    </a>
                  </li>
                </ul>
                <div class="tab-content ">
                  <div id="Market_Trade" class="tab-pane fade in active show">
                    <article className="ordre-book">
                      <section class="orderbook-header">
                        <table width="100%">
                          <tr>
                            <th class="price">Price</th>
                            <th>Amount</th>
                            <th>Time </th>
                          </tr>
                        </table>
                      </section>
                      <section class="side" id="asks">
                        <table width="100%">
                          <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr>
                          <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr>
                        </table>
                      </section>
                    </article>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 p-0">
              <div className="pair_selec_tab order_history">
                <ul class="nav nav-tabs pdint_bouut">
                  <li class="active">
                    <a data-toggle="tab" href="#Open_orders" className="active">
                      Open Orders
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#Order_h">
                      Order History
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#Trade_h">
                      Trade History
                    </a>
                  </li>
                </ul>
                <div class="tab-content min-hight">
                  <div id="Market_Trade" class="tab-pane fade in active show">
                    <p className="registyre_wes">
                      <a href="">Log In</a> or<a href=""> Register Now</a> to
                      trade
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
