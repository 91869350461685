import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Home() {
 
  return (
    <div className="header ">
      <Header />
      <main>
      <div className="terms_contition">
        <h1>ADVERB EXCHANGE FAQ</h1>
        <div className="container">
          <div className="row justify-center">
            <div className="col-lg-10">
              <div class="mx-auto u-para--readable">
                <h4>KNOW YOUR CUSTOMER(KYC)</h4>
                <h4>VERIFICATION</h4>
                <p class="u-para--readable mb-6">
                <b>What documents do I need to submit for KYC verification?</b>
                  {/*  */}
                </p>
                <p class="u-para--readable mb-6">
                You will need to submit the following documents to complete your KYC. </p>
                <p class="u-para--readable mb-6"> * Tax ID Verification: PAN Card number and date of birth (mandatory for Indian users) </p>
                <p class="u-para--readable mb-6">  * National Identity document (Digilocker): Aadhaar card number and OTP on the mobile number linked </p>
                <p class="u-para--readable mb-6">   * Manual Flow: Valid Passport (front and back) </p>
                <p class="u-para--readable mb-6">    * Liveness check: Live Selfie </p>
                <p class="u-para--readable mb-6">    * International users: Please get in touch with our support team, and they will assist you further in the KYC verification process. </p>
                
                <p class="u-para--readable mb-6">
                <b>Why am I unable to upload my documents?</b>
                </p>
                <p class="u-para--readable mb-6">
                There could be several reasons why you might be experiencing difficulties uploading your documents for KYC verification. Here are a few possible explanations </p>
                <p class="u-para--readable mb-6">* Technical Issues: It's possible that there could be technical glitches or temporary server problems on our site that are preventing you from uploading your documents. In such cases, it's advisable to try again after some time or contact the platform's customer support for assistance. </p>
                <p class="u-para--readable mb-6">* File Format or Size Restrictions: Make sure that your documents formats include PDF, JPEG, or PNG. Additionally, ensure that the file size is within the allowed limit." </p>
                <p class="u-para--readable mb-6">* Poor Internet Connection: If your internet connection is unstable or slow, it may hinder the document upload process. Check your internet connection, and try uploading the documents again when you have a stable and reliable connection. </p>
                <p class="u-para--readable mb-6">* Incorrect Document Format: Ensure that you are uploading the correct type of document for each category (e.g., ID proof, address proof). </p>

                <p class="u-para--readable mb-6"> If you have checked the above points and are still encountering issues, it's recommended to reach out to the our customer support. They will be able to provide you with specific guidance and assistance to resolve the problem you're facing with document uploads.
                </p>
                <p class="u-para--readable mb-6">
                <b>Will my KYC documents be safe with AdverbEx?</b>
                </p>
                <p class="u-para--readable mb-6">
                  At Adverb Exchange, we prioritize the security of our platform and your information. We use robust security software for both our crypto-wallets and identity management system to ensure the safety of your data. Rest assured that your information is in safe hands with us.
                </p>
                <p class="u-para--readable mb-6">
                  How much time will it take to complete my KYC process?
                </p>
                <p class="u-para--readable mb-6">
                    Adverb Exchange offers a fast and efficient KYC verification process. Through our automated system, the verification is typically completed within 10 minutes. However, in cases where manual processing is required, it may take between 24 and 48 hours to complete the verification process.
                </p>
                <h4>ACCOUNT MANAGEMENT</h4>
                <h4>CREATE AN ACCOUNT</h4>
                <p class="u-para--readable mb-6">
                  <b>Can I create a new account with the same set of KYC documents and bank account?</b>
                </p>
                <p class="u-para--readable mb-6">
                 Please be aware that Adverb Exchange allows only one account per user with the same set of KYC documents. This means that you cannot have multiple accounts on Adverb Exchange using identical KYC documents. If you have any further inquiries or require clarification, please don't hesitate to contact our support team.
                </p>
                <p class="u-para--readable mb-6">
                  <b>I am unable to sign up. What should I do?</b>
                </p>
                <p class="u-para--readable mb-6">
                If you are experiencing difficulties signing up, please perform the following checks: </p>

                <p class="u-para--readable mb-6">* Verify that the registered email ID provided is correct and matches the email you are using for the sign-up process. </p>

                <p class="u-para--readable mb-6">* Check your spam and junk folders in your email account for any automated emails from Adverb Exchange. Sometimes, these emails may end up in those folders. </p>

                <p class="u-para--readable mb-6">By following these steps, you can help ensure that you receive important communications from Adverb Exchange during the sign-up process. If you continue to face issues or require further assistance, please feel free to contact our support team. </p>
                
                <p class="u-para--readable mb-6">
                  <b>How do I sign up on AdverbEx?</b>
                </p>
                <p class="u-para--readable mb-6">
                  YouTube video link
                </p>
                <h4>LOGIN</h4>
                <p class="u-para--readable mb-6"><b>How to change my password on Adverb Exchange?</b></p>
                <p class="u-para--readable mb-6">
                Follow the steps below to change your Adverb Exchange account password: </p>
                <p class="u-para--readable mb-6">* Login to Adverb Exchange and click on the Dashboard on top right of the screen. </p>
                <p class="u-para--readable mb-6"> * Tap on Security. </p>
                <p class="u-para--readable mb-6">* Click On change password </p>
                <p class="u-para--readable mb-6">* Provide the necessary details [Old / New password] and click [Change Password]. </p>
                <p class="u-para--readable mb-6">* Your password will be reset successfully.
                  </p>
                  <p class="u-para--readable mb-6">
                   <b>How do I reset my password?</b>
                  </p>
                  <p class="u-para--readable mb-6">
                  Follow the steps below to reset your Adverb Exchange account password:</p>
                  <p class="u-para--readable mb-6">* Click [Forgot password] on the login page.</p>
                  <p class="u-para--readable mb-6">* Provide the necessary details [Registered email ) and click [Submit].</p>
                  <p class="u-para--readable mb-6">* Provide the OTP received on registered email and mobile number.</p>
                  <p class="u-para--readable mb-6">* You may have received an email, please check your email and click on "Reset Password"</p>
                  
                  <p class="u-para--readable mb-6">
                   <b>Is there an expiry period set on passwords?</b>
                  </p>
                  <p class="u-para--readable mb-6">
                  No, there is no expiry period for your password.
                  </p>
                  <p class="u-para--readable mb-6">
                  <b>How do I log in to my Adverb Exchange account?</b>
                  </p>
                  <p class="u-para--readable mb-6">
                  Follow the steps below to log into your Adverb Exchange account: </p>
                  <p class="u-para--readable mb-6">* Go to the Adverb website home page.</p>
                  <p class="u-para--readable mb-6">* Provide the registered email and password and click Continue.
                  </p>
                <h4>ACCOUNT MANAGEMENT INR DEPOSIT & WITHDRAWAL</h4>
                <h4>NET BANKING - DEPOSIT METHOD</h4>
                <p class="u-para--readable mb-6">
                <b>Am I eligible for net banking?</b>
                </p>
                <p class="u-para--readable mb-6">
                NetBanking option is currently available for the below listed banks only. Since the deposit option is supported by a list of limited banks, please do not raise a support ticket for the same. We would suggest you kindly use another deposit option.
                </p>
                <p class="u-para--readable mb-6">
                Supported Bank List:
                    Bank of India
                    Bank of Maharashtra
                    Indian Bank
                    Punjab National Bank - retail
                    Union Bank
                    Lakshmi Vilas Bank NetBanking
                    Indian Overseas Bank
                    Dhanalakshmi Bank
                </p>
                <p class="u-para--readable mb-6">
                <b>Is there any additional fee for deposit transactions?</b>
                </p>
                <p class="u-para--readable mb-6">
                 You can deposit INR into your Adverb Exchange wallet via IMPS using the Bank Transfer (0% fees) option.
                 Adverb Exchange charges no fees as an exchange. However, users are mandatorily required to complete their KYC in order to avail INR deposit/withdrawal facilities.
                </p>
                <p class="u-para--readable mb-6">
                  <b>I am not able to log in to my bank account?</b>
                </p>
                <p class="u-para--readable mb-6">
                   Please make sure you are trying to log in using your registered bank account only. The deposit must be done from the bank account that you have linked with your AdverbEx profile. In case you are still facing issues while logging in, we would request you to contact your respective bank for further assistance.
                </p>
                <p class="u-para--readable mb-6">
                <b>Can I deposit money from someone else's bank account into my AdverbEx account?</b>
                </p>
                <p class="u-para--readable mb-6">
                No.The deposit must be done only from the bank account that you have linked with your Adverbex profile.
                </p>
                <p class="u-para--readable mb-6">
                <b>My transaction attempt failed, what do I do now?</b>
                </p>
                <p class="u-para--readable mb-6">
                If funds have been deducted from your bank account, we kindly request you to promptly share the bank statement with us. We will investigate the issue with our partner and initiate the payment reconciliation process. Any deducted amount will be refunded to your AdverbEx wallet within 7 bank working days once we receive a response from our partner. 
                </p>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
