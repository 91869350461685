import React, {useState, useEffect} from "react";
import Header from "./Header";
import {Button} from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";

function Home() {
  return (
    <div className="header ">
      <main>
        <div className="login_form p-0">
          <div className="comming_section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="">
                    <h1>Coming</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="comming_section tow">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="">
                    <h1>Soon</h1>
                    <Link to="/">Return to Home</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
