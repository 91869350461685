import React, {useEffect} from "react";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import {Button} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
// import Bgvedio from "../Image/globe-fiat-block.mp4";
import BannerUI from "./Banner";
import {toast} from "react-toastify";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";
import Moment from "moment";
import Pagination from "react-js-pagination";
import Countdownchat from "./Countdown";
import Countdown from "react-countdown";

import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
  InstapaperIcon,
  InstapaperShareButton,
} from "react-share";

import { socket } from "../core/context/socket";

function Home() {
  const [loader, setLoader] = useState(false);
  const initialFormValue = {
    message: "",
    file: "",
    type: "",
    orderId: "",
    p2porderId: "",
  };

  const p2pFormValue = {
    qty: "",
    total: "",
  };

  const [p2pData, setp2pData, p2pDataref] = useState("");
  const [orderType, setorderType, orderTyperef] = useState("");
  const [p2pdate, setp2pDate, p2pdateref] = useState("");
  const [profileData, setprofileData, profileDataref] = useState("");
  const [formValue, setFormValue, formValueref] = useState(initialFormValue);
  const [p2pfile, setp2pfile, p2pfileref] = useState("");
  const [chatloading, setchatloading] = useState(false);
  const {message, file, type, orderId, p2porderId} = formValue;
  const [p2pchat, setp2pchat, p2pchatref] = useState("");
  const [interval, setintervalchat, intervalref] = useState("");
  const [runningTimer, setRunningTimer] = useState(false);
  const [bankData, setbankData, bankDataref] = useState("");
  const [socket_token, set_socket_token, socketref] = useState("");
  const [notifyp2pData, setnotifyp2pData, notifyp2pDataref] = useState("");
  const [p2pformValue, setp2pFormValue, p2pformValueref] =
    useState(p2pFormValue);
  const [notifymessage, setnotifymessage, notifymessageref] = useState("");
  const [confirmp2pcheck, setconfirmp2pcheck, confirmp2pcheckref] = useState(
    []
  );
  const {qty, total} = p2pformValue;
  const [p2pbalance, setp2pbalance, p2pbalanceref] = useState("");
  const [disputefile, setdisputefile, disputefileref] = useState("");
  const [disputequery, setdisputequery, disputequeryref] = useState("");
  const [confirmp2porder, setconfirmp2porder, confirmp2porderref] =
    useState("");
  const [p2pbankcheck, setp2pbankcheck, p2pbankcheckref] = useState(false);
  const [p2pbankData, setp2pbankData, p2pbankDataref] = useState("");
  const [confirmOrders, setconfirmOrders, confirmOrdersref] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getProfile();
    getp2pOrder();
    getconfirmOrder();
  }, [0]);

  const closePopup = async () => {};

  const getProfile = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);
      console.log("get profile---", resp);
      if (resp.status) {
        setprofileData(resp.data);
        //getp2pOrder();
      }
    } catch (error) {}
  };

  const getp2pOrder = async () => {
    var onj = {
      orderId: window.location.href.split("/").pop(),
    };
    var data = {
      apiUrl: apiService.viewp2pOrder,
      payload: onj,
    };
    var resp = await postMethod(data);
    console.log(resp.Message, "-=-=-resp=-=-");
    //console.log("profileDataref.current._id====",profileDataref.current._id.toString());
    //  console.log("resp.Message.userId.toString()====",resp.Message.userId.toString());
    if (resp) {
      var data = resp.Message;
      setp2pData(resp.Message);
      setp2pbalance(resp.p2pbalance);
      setconfirmOrders(resp.getconfirmOrders);
      if (resp.bank) {
        setbankData(resp.bank);
      }

      if (resp.Message.orderType == "buy") {
        if (
          profileDataref.current._id.toString() ==
          resp.Message.userId.toString()
        ) {
          setorderType("Buy");
        } else {
          setorderType("Sell");
        }
      } else {
        if (
          profileDataref.current._id.toString() ==
          resp.Message.userId.toString()
        ) {
          setorderType("Sell");
        } else {
          setorderType("Buy");
        }
      }
      console.log("orderTyperef====", orderTyperef.current);
      var dates = Moment(resp.Message.createdAt).format("DD.MM.YYYY h:m a");
      setp2pDate(dates);
    }
  };

  const getconfirmOrder = async () => {
    var onj = {
      orderId: window.location.href.split("/").pop(),
    };
    var data = {
      apiUrl: apiService.confirmp2porder,
      payload: onj,
    };
    var resp = await postMethod(data);
    console.log(resp, " confirmp2porder -=-=-resp=-=-");
    if (resp) {
      var data = resp.Message;
      setconfirmp2porder(resp.Message);
      setp2pbankData(resp.bank_details);
      setp2pbankcheck(true);
    }
  };

  const handleChange_buycancel = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    console.log("e.target===", e.target.innerText);
    buyer_cancel();
  };

  const handleChange_sellcancel = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    console.log("e.target===", e.target.innerText);
    seller_cancel();
  };
  const buyer_cancel = async (status) => {
    try {
      var obj = {
        orderId: window.location.href.split("/").pop(),
        status: status,
      };
      var data = {
        apiUrl: apiService.buyer_cancel,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getp2pOrder();
        navigate("/p2p");
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {}
  };

  const seller_cancel = async (status) => {
    try {
      var obj = {
        orderId: window.location.href.split("/").pop(),
        status: status,
      };
      var data = {
        apiUrl: apiService.seller_cancel,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getp2pOrder();
        navigate("/p2p");
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {}
  };

  const copy_to_clipboard = async (type, text) => {
    navigator.clipboard.writeText(text);
    toast.success(type + " copied successfully");
  };

  return (
    <div className="header">
      <Header />
      <main>
        {loader == true ? (
          <div class="spinner css-ezm1fk">
            <div class="css-1qoumk2"></div>
            <div class="css-1v19680"></div>
            <div class="css-1v90jie"></div>
            <div class="css-19p0rvp"></div>
          </div>
        ) : (
          <>
            <main className="chat_mainpadi">
              <div className="">
                <div className={orderTyperef.current=="Buy"?"bg_roe":"bg_roe_sell"}>
                  <div className="container">
                    <div className="row justify-center">
                      <div className="col-lg-12">
                        <div className="chat_details">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="contnte_chat_section">
                                  
                                  {orderTyperef.current == "Buy" ? (
                                    <p>
                                    <span className="green">{orderTyperef.current}</span> {orderTyperef.current} {p2pDataref.current.firstCurrency} 
                                    </p>
                                  ) : (
                                    <p>
                                      <span className="red">{orderTyperef.current}</span> {orderTyperef.current} {p2pDataref.current.firstCurrency}
                                      </p>
                                  )}
                                  
                                  {/* <p className="timersection">
                                    This order is created, please wait for
                                    system conformation
                                    <span className="new_count_secti">
                                      <Countdownchat />
                                    </span>
                                  </p> */}
                                </div>
                              </div>
                              {p2pdateref.current != "" ? (
                              <div className="col-lg-6">
                                <div className="contnte_chat_section">
                                  <p className="text-right">
                                    Order Number{" "}
                                    <span>{p2pDataref.current._id.substring(0,12)}</span>
                                  </p>
                                  <p className="text-right">
                                    Time Created{" "}
                                    <span>{p2pdateref.current}</span>
                                  </p>
                                </div>
                              </div>
                              ) : ("")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container">
                  <div className="row justify-center">
                    <div className="col-lg-12">
                      <div className="chart_section_new">
                        <div className="contanier">
                          <div className="row">
                            <div className="col-lg-7">
                            { p2pDataref.current != "" ? (
                            <>
                             <div className="chat_details_ss">
                                <h4> Order Info</h4>
                                <div className="account_dteils mb-4">
                                  <div className="section_contant_row">
                                    <label>Price</label>
                                    <p>{p2pDataref.current.price}{" "}
                                      {p2pDataref.current.secondCurrnecy}</p>
                                  </div>
                             
                              </div>

                              <div className="account_dteils mb-4">
                                  <div className="section_contant_row">
                                    <label>Quantity</label>
                                    <p>{+p2pDataref.current.totalAmount - +p2pDataref.current.processAmount}{" "}
                                      {p2pDataref.current.firstCurrency}</p>
                                  </div>
                             
                              </div>

                                <h4 className="mb-4">
                                My Payment Methods
                                </h4>
                                 {bankDataref.current ? (
                                  <>
                                  <p className="lable_section">Bank Transfer:</p>
                                    <div className="account_dteils mb-4">
                                    <div className="section_contant_row">
                                      <label>Account Number</label>
                                      <p>{bankDataref.current.Account_Number}</p>
                                    </div>
                                    <div className="section_contant_row">
                                      <label>Account Holder</label>
                                      <p>{
                                          bankDataref.current
                                            .Accout_HolderName
                                        }</p>
                                    </div>
                                    <div className="section_contant_row">
                                      <label>Bank Name</label>
                                      <p>{bankDataref.current.Bank_Name}</p>
                                    </div>
                                    <div className="section_contant_row">
                                      <label>Branch Name</label>
                                      <p>{bankDataref.current.Branch_Name}</p>
                                    </div>
                                    <div className="section_contant_row">
                                      <label>IFSC Code</label>
                                      <p>{bankDataref.current.IFSC_code}</p>
                                    </div>
                                  </div>
                                  </>
                                ) : ("")}
                                {bankDataref.current && bankDataref.current.Gpay_Number != undefined && bankDataref.current.Gpay_Number != "" ? (
                                <>
                                <p className="lable_section">UPI ID:</p>
                                <div className="account_dteils mb-4">
                                  <div className="section_contant_row">
                                    <label>UPI Id</label>
                                    <p>{bankDataref.current.Gpay_Number}</p>
                                  </div>
                                </div>
                                </>
                                ) : ("")}
                                {bankDataref.current && bankDataref.current.Paytm_Number != undefined && bankDataref.current.Paytm_Number != "" ? (
                                <>
                                <p className="lable_section">Paytm:</p>
                                <div className="account_dteils mb-4">
                                  <div className="section_contant_row">
                                    <label>Paytm</label>
                                    <p>{bankDataref.current.Paytm_Number}</p>
                                  </div>
                                </div>
                                </>
                                ) : ("")}

                                {orderTyperef.current == "Sell" &&
                                  profileDataref.current._id.toString() ==
                                    p2pDataref.current.userId.toString() &&
                                  //confirmOrdersref.current.length == 0 &&
                                  (p2pDataref.current.status != "filled" && p2pDataref.current.status != "cancelled")  ? (
                                    <>
                                    <h4 className="mb-4"></h4>
                                    <div className="action_btn">
                                    <Button className="width_full" onClick={handleChange_sellcancel}>
                                    Cancel Order
                                    </Button>
                                    </div>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {orderTyperef.current == "Buy" &&
                                  profileDataref.current._id.toString() ==
                                    p2pDataref.current.userId.toString() &&
                                 // confirmOrdersref.current.length == 0 &&
                                  (p2pDataref.current.status != "filled" && p2pDataref.current.status != "cancelled")  ? (
                                    <>
                                    <h4 className="mb-4"></h4>
                                    <div className="action_btn">
                                    <Button className="width_full" onClick={handleChange_buycancel}>
                                    Cancel Order
                                    </Button>
                                    </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                              </div>
                              </>
                            ) : ("")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>

        <div id="success_msg" class="modal launchpad_doce fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-body model_confirms">
              <h1> {notifymessageref.current}</h1>
            </div>
            <div class="modal-footer">
              <Button
                type="button"
                class="btn btn-default"
                data-dismiss="modal"
                onClick={closePopup}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
          </>
        )}
      </main>
      <Footer />
    </div>
  );
}

export default Home;
